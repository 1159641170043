import {
  Center,
  VStack,
  HStack,
  Image,
  Text,
  Heading,
  Button,
} from "@chakra-ui/react";
import RivrBot from "../assets/rivr-bot.png";
import { ArrowLeft } from "@icon-park/react";

const PageNotFound = () => {
  return (
    <Center w="100%" h="100vh" bg="gray.900">
      <VStack spacing={8} justifyContent={"center"} p={6}>
        <HStack
          spacing={3}
          flexWrap={"nowrap"}
          h={28}
          justifyContent={"center"}
        >
          <Text color="rivr.red" fontSize={"9xl"} fontWeight={"extrabold"}>
            4
          </Text>
          <Image src={RivrBot} height="7rem" borderRadius={"full"} />
          <Text color="rivr.red" fontSize={"9xl"} fontWeight={"extrabold"}>
            4
          </Text>
        </HStack>
        <Heading as="h1">Page Not Found</Heading>
        <Button
          colorScheme="gray"
          size="lg"
          leftIcon={<ArrowLeft />}
          as="a"
          href="/"
        >
          Back to Rivr
        </Button>
      </VStack>
    </Center>
  );
};

export default PageNotFound;
