import { Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { SVGProps } from "react";

const MouseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={31}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={"#e43a19"}
        fillRule="evenodd"
        stroke={"#fff"}
        strokeLinecap="square"
        strokeWidth={2}
        d="M21.993 14.425 2.549 2.935l4.444 23.108 4.653-10.002z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={"#22c55e"} d="M0 0h26v31H0z" />
      </clipPath>
    </defs>
  </svg>
);

const HoverCursor = ({ text, subtext }: { text: string; subtext: string }) => (
  <motion.div
    style={{
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    }}
    initial={{ opacity: 0, scale: 0.75 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.5 }}
    transition={{ duration: 0.2 }}
  >
    <MouseIcon />
    <VStack
      spacing={0}
      align={"start"}
      bg="rivr.red"
      px={3}
      py={1}
      borderRadius="md"
      fontSize="sm"
      ml={5}
      minW={"max-content"}
      shadow={"base"}
    >
      <Text fontWeight={"semibold"}>{text}</Text>
      <Text fontSize={"xs"}>{subtext}</Text>
    </VStack>
  </motion.div>
);

export default HoverCursor;
