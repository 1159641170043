import { Flex, Text, Image, Link, VStack, SimpleGrid } from "@chakra-ui/react";
import LogoFull from "../../assets/rivr-logo.svg";
import BotniVisionLogo from "../../assets/botni-vision-logo.svg";
import Grad3 from "../../assets/grad3.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Flex
      justifyContent={"center"}
      w="100%"
      backgroundImage={Grad3}
      backgroundPosition={"top center"}
      backgroundRepeat={"no-repeat"}
      backgroundSize={"cover"}
    >
      <Flex
        w={"100%"}
        maxW={"1400px"}
        mx={6}
        h={"100%"}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"stretch"}
      >
        <Flex
          className="footer-wrapper"
          flexDirection={"row"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          my={12}
        >
          <Flex
            className="footer-logo-tagline"
            flexDirection={"column"}
            w={"200px"}
            my={3}
          >
            <Link href="/">
              <Image src={LogoFull} w={"150px"} alt="Rivr Logo" />
            </Link>
            <Text textAlign={"left"} mt={4} mb={6} sx={{ textWrap: "balance" }}>
              Next-gen technology for next-gen marketers
            </Text>
          </Flex>

          <SimpleGrid columns={[2, 3, 4, 5]} spacing={10}>
            <VStack alignItems={"flex-start"}>
              <Text fontWeight="extrabold" color="rivr.red" letterSpacing={1}>
                PRODUCT
              </Text>
              <Link href={"/"} aria-label="Creators">
                Creators
              </Link>
              <Link href={"/brands"} aria-label="Brands">
                Brands
              </Link>
              <Link href={"/roadmap"} aria-label="Roadmap">
                Roadmap
              </Link>
            </VStack>

            <VStack alignItems={"flex-start"}>
              <Text fontWeight="extrabold" color="rivr.red" letterSpacing={1}>
                RESOURCES
              </Text>
              <Link href="/blog" aria-label="Blog">
                Blog
              </Link>
            </VStack>
            {/* <Link
                href="https://rivr.stream/case-studies"
                aria-label="Case studies"
              >
                Case studies
              </Link>
             */}

            <VStack alignItems={"flex-start"}>
              <Text fontWeight="extrabold" color="rivr.red" letterSpacing={1}>
                COMPANY
              </Text>
              <Link
                href="https://botni.vision"
                isExternal
                aria-label="Botni.Vision"
              >
                Botni.Vision
              </Link>
              <Link href="/careers" aria-label="Careers">
                Careers
              </Link>
              <Link href={"mailto:contact@rivr.stream"} aria-label="Contact">
                Contact
              </Link>
              <Link href={"mailto:sales@rivr.stream"} aria-label="Sales">
                Sales
              </Link>
            </VStack>

            <VStack alignItems={"flex-start"}>
              <Text fontWeight="extrabold" color="rivr.red" letterSpacing={1}>
                COMMUNITY
              </Text>
              <Link
                href="http://x.com/rivrstream"
                isExternal
                aria-label="Twitter/X"
              >
                X/Twitter
              </Link>
              <Link
                href="https://discord.gg/WTkWJKzyQK"
                isExternal
                aria-label="Discord"
              >
                Discord
              </Link>
            </VStack>

            <VStack alignItems={"flex-start"}>
              <Text fontWeight="extrabold" color="rivr.red" letterSpacing={1}>
                PRIVACY
              </Text>
              <Link
                href="/privacy-terms-of-service"
                aria-label="Privacy Policy"
              >
                Privacy Policy
              </Link>
              <Link
                href="/privacy-terms-of-service#terms"
                aria-label="Terms of Service"
              >
                Terms of Service
              </Link>
            </VStack>
          </SimpleGrid>
        </Flex>

        <Flex
          flexDirection={"column"}
          align={"center"}
          justify={"center"}
          mt={12}
          textAlign={"center"}
        >
          <Link href="https://botni.vision" isExternal mb={4}>
            <Image
              src={BotniVisionLogo}
              w={"160px"}
              alt="botni.vision logo"
              aria-label="botni.vision"
            />
          </Link>
          <Text fontSize={["xs", "sm", "sm"]}>
            Made with ❤️ and 🧠 in Montreal, QC
          </Text>
          <Text fontSize={["xs", "sm", "sm"]}>
            A Botni.Vision Inc. company © {currentYear} and beyond.
          </Text>
          <Text fontSize="xs" my={4} color="gray.400">
            Any logos, images, or videos used on this site belong to their
            respective copyright owners.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
