import { Box, VStack, Text, Flex, Heading } from "@chakra-ui/react";
import ImageModal from "../ImageModal/imagemodal";

const BlogImageQuote = ({ image, alt, quote, personName, personTitle }) => {
  return (
    <Box py={{ lg: 16, base: 10 }}>
      <Flex
        flexDirection={{ lg: "row", base: "column" }}
        bg="rivr.red"
        borderRadius={"3xl"}
        alignItems={"stretch"}
      >
        <Flex w={{ lg: "60%", base: "100%" }}>
          <ImageModal
            src={image}
            alt={alt}
            objectFit="cover"
            borderRadius={{
              lg: "1.5rem 0rem 0rem 1.5rem",
              base: "1.5rem 1.5rem 0rem 0rem",
            }}
            borderWidth={2}
            borderColor={"rivr.red"}
            borderStyle={"solid"}
          />
        </Flex>
        <VStack
          alignItems={"flex-start"}
          justifyContent={"center"}
          w={{ lg: "40%", base: "100%" }}
          px={12}
          pb={12}
          pt={16}
        >
          <Text
            lineHeight={0}
            pt={2}
            textAlign={"left"}
            fontSize={"6xl"}
            color={"rivr.black"}
            pointerEvents={"none"}
            w={"100%"}
          >
            “
          </Text>
          <Heading fontSize={{ lg: "2xl", base: "xl" }} pb={3}>
            {quote}
          </Heading>
          <Text
            lineHeight={0}
            pt={2}
            textAlign={"right"}
            fontSize={"6xl"}
            color={"rivr.black"}
            pointerEvents={"none"}
            w={"100%"}
          >
            ”
          </Text>
          <VStack fontWeight={"medium"} alignItems={"flex-start"} spacing={0}>
            {personName && <Text>{personName}</Text>}
            {personTitle && <Text color="rivr.black">{personTitle}</Text>}
          </VStack>
        </VStack>
      </Flex>
    </Box>
  );
};

export default BlogImageQuote;
