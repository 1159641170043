import { Box } from "@chakra-ui/react";
import B2BHeading from "../components/B2B/B2BHeading";
import B2BBenefits from "../components/B2B/B2BBenefits";
import B2BClients from "../components/B2B/B2BClients";
import B2BCases from "../components/B2B/B2BCases";
import B2BFeatures from "../components/B2B/B2BFeatures";
import B2BCreatorProgram from "../components/B2B/B2BCreatorProgram";
import B2BContact from "../components/B2B/B2BContact";
import B2BFaq from "../components/B2B/B2BFaq";
import Grad from "../assets/grad.png";
import NewGrad from "../assets/newgrad.png";

const B2B = () => {
  return (
    <Box w={"100%"} height={"100%"} position={["relative"]} bg={"rivr.black"}>
      <Box
        backgroundImage={Grad}
        backgroundPosition={{
          base: "center center",
          md: "center center",
          lg: "top center",
        }}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"contain"}
      >
        <B2BHeading />
        <B2BBenefits />
        <B2BClients />
      </Box>
      <B2BCases />
      <Box
        w={"100%"}
        backgroundImage={NewGrad}
        backgroundPosition={"center center"}
        backgroundRepeat={"no-repeat"}
        backgroundSize={{ base: "300%", md: "200%", lg: "cover" }}
      >
        <B2BFeatures />
        <B2BCreatorProgram />
      </Box>
      <B2BContact />
      <B2BFaq />
    </Box>
  );
};

export default B2B;
