import {
  VStack,
  HStack,
  Flex,
  Image,
  Heading,
  Tag,
  LinkOverlay,
  SimpleGrid,
  Box,
  Divider,
  Text,
  LinkBox,
  Link,
} from "@chakra-ui/react";

import Grad from "../../../assets/newgrad.png";
import DeejayKnightSQ from "../../../assets/blog/spotlights/deejay-knight/dj-header-sq.png";
import LowcoAnnounceSQ from "../../../assets/blog/news/lowco-announce/lowco-header-sq.png";
import AidanAnnounce from "../../../assets/blog/news/aidan-announce/aidan-header.png";

const feature = {
  imageUrl: AidanAnnounce,
  link: "/blog/rivr-news-aidan-director-of-sales",
  tag: "Press Release",
  tagColor: "orange",
  headline: "Botni.Vision welcomes new Director of Sales, Aidan Kessell",
  subline:
    "Kessell, an accomplished professional in the marketing and influencer space, joins the Montreal-based tech startup to lead sales strategy and drive growth for Rivr.",
  date: "Jul 29, 2024",
};

const blogPosts = [
  {
    imageUrl: LowcoAnnounceSQ,
    tag: "Press Release",
    tagColor: "orange",
    title: "Botni.Vision welcomes new Head of Product, Kacey “Lowco” Shields",
    date: "Mar 13, 2024",
    link: "/blog/rivr-news-lowco-head-of-product",
  },
  {
    imageUrl: DeejayKnightSQ,
    tag: "Creator Spotlight",
    tagColor: "green",
    title:
      "DeejayKnight – How the beloved streamer is creating more feel-good content in less time with Rivr",
    date: "Jan 30, 2024",
    link: "/blog/creator-spotlight-deejayknight",
  },
  /*
  {
    imageUrl:
      "https://images-cdn2.welcomesoftware.com/Zz0xODg4MjVhNDVhMDYxMWVkYTUzOTUyZGQ0NDQ5OWMzNQ==",
    tag: "Partnerships",
    tagColor: "yellow",
    title:
      "Deejay Knight something something something something something something",
    date: "Sep 29, 2023",
    link: "",
  },
  {
    imageUrl:
      "https://images-cdn2.welcomesoftware.com/Zz0xODg4MjVhNDVhMDYxMWVkYTUzOTUyZGQ0NDQ5OWMzNQ==",
    tag: "Creator Spotlight",
    tagColor: "green",
    title:
      "Deejay Knight something something something something something something",
    date: "Sep 29, 2023",
    link: "",
  },
  {
    imageUrl:
      "https://images-cdn2.welcomesoftware.com/Zz0xODg4MjVhNDVhMDYxMWVkYTUzOTUyZGQ0NDQ5OWMzNQ==",
    tag: "Case Study",
    tagColor: "blue",
    title: "Deejay Knight something something something ",
    date: "Sep 29, 2023",
    link: "",
  }, */
];

const BlogIndex = () => {
  return (
    <VStack
      justifyContent={"center"}
      bg={"rivr.black"}
      backgroundImage={Grad}
      backgroundPosition={"top center"}
      backgroundRepeat={"no-repeat"}
      backgroundSize={"cover"}
    >
      <VStack
        maxW={"1400px"}
        mx={6}
        pt={[28, 28, 36]}
        pb={{ lg: 32, base: 14 }}
      >
        <Box w={"100%"}>
          <VStack
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            w={"100%"}
            pb={20}
          >
            <Heading>Blog</Heading>
            <Text color={"gray.300"}>
              News, insights, and case studies from the Rivr team.
            </Text>
          </VStack>
          <Divider bg={"gray.400"} mb={"2 !important"} />
          <HStack spacing={2} pb={10} alignSelf={"flex-start"}>
            <Box h={3} w={3} bg={"rivr.red"} borderRadius={"full"} />
            <Text
              fontSize={"sm"}
              letterSpacing={1}
              fontWeight={"medium"}
              textTransform={"uppercase"}
            >
              Featured
            </Text>
          </HStack>
          <Flex
            flexDirection={"row"}
            pb={16}
            gap={{ lg: 12, base: 6 }}
            flexWrap={"wrap"}
          >
            <Flex w={{ lg: "55%", base: "100%" }}>
              <Link href={feature.link}>
                <Image
                  borderRadius={"3xl"}
                  maxW={"100%"}
                  fit={"contain"}
                  borderWidth={2}
                  borderColor={"rivr.red"}
                  borderStyle={"solid"}
                  src={feature.imageUrl}
                />
              </Link>
            </Flex>
            <VStack
              alignItems={"flex-start"}
              justifyContent={"center"}
              flexWrap={"nowrap"}
              spacing={4}
              w={{ lg: "40%", base: "100%" }}
            >
              <Tag
                size="sm"
                textTransform={"uppercase"}
                px={2}
                py={1.5}
                colorScheme={feature.tagColor}
                letterSpacing={1}
              >
                {feature.tag}
              </Tag>
              <Heading
                fontSize={{ lg: "4xl", md: "3xl", base: "2xl" }}
                fontWeight={"semibold"}
                as="a"
                color="gray.50"
                transition="color"
                transitionDuration={"normal"}
                href={feature.link}
                _hover={{ color: "rivr.red" }}
              >
                {feature.headline}
              </Heading>
              <VStack
                alignItems={"flex-start"}
                flexDirection={{ lg: "column", base: "column-reverse" }}
              >
                <Text
                  textTransform={"uppercase"}
                  fontSize={"xs"}
                  color="gray.400"
                  fontWeight={"semibold"}
                  letterSpacing={1}
                >
                  {feature.date}
                </Text>
                <Text
                  fontSize={"xl"}
                  pt={{ lg: 8, base: 0 }}
                  pb={{ lg: 0, base: 4 }}
                  color="gray.200"
                >
                  {feature.subline}
                </Text>
              </VStack>
            </VStack>
          </Flex>
        </Box>
        {blogPosts.length !== 0 && (
          <>
            <Divider bg={"gray.400"} mb={"2 !important"} />
            <HStack spacing={2} pb={10} alignSelf={"flex-start"}>
              <Box h={3} w={3} bg={"rivr.red"} borderRadius={"full"} />
              <Text
                fontSize={"sm"}
                letterSpacing={1}
                fontWeight={"medium"}
                textTransform={"uppercase"}
              >
                Most Recent
              </Text>
            </HStack>
            <SimpleGrid
              columns={{ lg: 2, base: 1 }}
              spacing={{ lg: 10, base: 6 }}
              w={"100%"}
            >
              {blogPosts.map((post, index) => (
                <LinkBox key={index}>
                  <HStack
                    spacing={10}
                    w="100%"
                    p={2}
                    bg={"transparent"}
                    transition={"all 0.2s"}
                    _hover={{ bg: "whiteAlpha.100" }}
                    borderRadius={"2rem 2rem 0 2rem"}
                    sx={{
                      "&:hover .post-title": {
                        color: "rivr.red",
                      },
                    }}
                  >
                    <Flex w="fit-content">
                      <LinkOverlay href={post.link}>
                        <Image
                          maxW={"185px"}
                          h={"100%"}
                          borderRadius={"3xl"}
                          src={post.imageUrl}
                        />
                      </LinkOverlay>
                    </Flex>
                    <VStack
                      alignItems={"flex-start"}
                      justifyContent={"flex-start"}
                      spacing={4}
                      w={"100%"}
                    >
                      <Tag
                        size="sm"
                        textTransform={"uppercase"}
                        px={2}
                        py={1.5}
                        colorScheme={post.tagColor}
                        letterSpacing={1}
                      >
                        {post.tag}
                      </Tag>
                      <Heading
                        fontSize={{ lg: "2xl", base: "lg" }}
                        fontWeight={"medium"}
                        as="a"
                        color="gray.50"
                        transition="color"
                        transitionDuration={"normal"}
                        href={post.link}
                        noOfLines={3}
                        className={"post-title"}
                      >
                        {post.title}
                      </Heading>
                      <Text
                        textTransform={"uppercase"}
                        fontSize={"xs"}
                        color="gray.400"
                        fontWeight={"semibold"}
                        letterSpacing={1}
                      >
                        {post.date}
                      </Text>
                      <Divider />
                    </VStack>
                  </HStack>
                </LinkBox>
              ))}
            </SimpleGrid>
          </>
        )}
      </VStack>
    </VStack>
  );
};

export default BlogIndex;
