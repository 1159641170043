import {
  Box,
  Button,
  Center,
  Heading,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

const accordionData = [
  {
    title: "Sr. Fullstack Developer/Engineer — 115-140k CAD + Equity",
    roles: [
      {
        heading: "Sr. Fullstack Developer/Engineer — Rivr",
        subPoints: [
          "Hybrid, Full Time — within Montreal",
          "115-140k CAD + Equity",
        ],
      },
      {
        heading: "Botni.Vision",
        paragraphs: [
          "Botni.Vision is Montreal based startup with a distributed team working in the media processing and video analysis space. Our first product, Rivr, enables users to search within video as easily as they search for video. Rivr turns any video into searchable experiences, making it easy to find your best moments, learn from your community, and share content like never before.",
          "Currently we work with creators, streamers, influencers, gamers, and enterprises, creating new technology to produce better content faster. We make the impossible possible by removing the bottlenecks of running large-scale video-first advertising campaigns while also providing an accessible, fun, and engaging tool for end-users.",
          "Our founders are developers and focused on technology—every day we’re working on fun, challenging, and first-to-market technology.",
        ],
      },
      {
        heading: "The Role",
        paragraphs: [
          "This is a leadership role within the development team, focused on the core technologies around architecture, development, scaling, and support. While this is a full stack position, this role will lean more towards the backend of the application. ",
        ],
      },
      {
        heading: "Qualifications",
        bulletPoints: [
          "8+ years experience with Python, Golang, Typescript, or similar",
          "5+ years experience with React",
          "Experience with GraphQL, WebSockets, and/or WebRTC",
          "Experience working with entity relational databases such as PostgreSQL",
          "Experience with complex interactive components, such as Plotly, D3, and Dash, Vulkan, WASM",
        ],
      },
      {
        heading: "Nice-to-haves",
        bulletPoints: [
          "Experience with Kubernetes and container workloads",
          "Experience with event driven systems",
          "Experience with FFmpeg/PyAV/LibAV",
          "Experience with big data or media streaming applications",
          "Experience with in-browser image, audio, and video manipulation tools",
        ],
      },
      {
        heading: "What we offer",
        bulletPoints: [
          "Early stage equity",
          "30 days of PTO",
          "Flexible hybrid schedule",
          "Competitive group health benefits",
        ],
      },
    ],
    buttonHref: "mailto:careers@rivr.stream",
  },
  {
    title: "Sr. Platform Developer/Engineer — 100-150k CAD + Equity",
    roles: [
      {
        heading: "Sr. Platform Developer/Engineer — Rivr",
        subPoints: [
          "Hybrid, Full Time — within Montreal",
          "100-150k CAD + Equity",
        ],
      },
      {
        heading: "Botni.Vision",
        paragraphs: [
          "Botni.Vision is Montreal based startup with a distributed team working in the media processing and video analysis space. Our first product, Rivr, enables users to search within video as easily as they search for video. Rivr turns any video into searchable experiences, making it easy to find your best moments, learn from your community, and share content like never before.",
          "Currently we work with creators, streamers, influencers, gamers, and enterprises, creating new technology to produce better content faster. We make the impossible possible by removing the bottlenecks of running large-scale video-first advertising campaigns while also providing an accessible, fun, and engaging tool for end-users.",
          "Our founders are developers and focused on technology—every day we’re working on fun, challenging, and first-to-market technology.",
        ],
      },
      {
        heading: "The Role",
        paragraphs: [
          "In this role, you will support the design, development, and deployment of a media analysis and production pipeline.  You will own infrastructure decisions across the platform, and support the development team with backend and operations.",
        ],
      },
      {
        heading: "Qualifications",
        bulletPoints: [
          "5+ years experience with Python",
          "Proficient in implementing observability practices to ensure system reliability and performance",
          "Skilled in utilizing messaging libraries and patterns such as NATs, gRPC, and REST",
          "Knowledgeable in infrastructure operations incl. updating to new versions of EKS and handling DB migrations",
          "Adept at AWS cost control and optimizing cloud resource usage",
          "Experience in managing core infrastructure, including TimescaleDBs and Kubernetes",
          "Capable of performing Site Reliability Engineering (SRE) tasks to maintain system stability",
          "Experienced with on-call responsibilities for infrastructure and core systems maintenance",
          "Experience with video encoding and streaming",
        ],
      },
      {
        heading: "Nice-to-haves",
        bulletPoints: [
          "Experience with Linux development",
          "Experience with Go, Rust, C++",
          "Experience with working with and maintaining open source projects",
        ],
      },
      {
        heading: "What we offer",
        bulletPoints: [
          "Early stage equity",
          "30 days of PTO",
          "Flexible hybrid schedule",
          "Competitive group health benefits",
        ],
      },
    ],
    buttonHref: "mailto:careers@rivr.stream",
  },
  {
    title: "Sr. Backend Developer/Engineer — 110-140k CAD + Equity",
    roles: [
      {
        heading: "Sr. Backend Developer/Engineer — Rivr",
        subPoints: [
          "Hybrid, Full Time — within Montreal",
          "110-140k CAD + Equity",
        ],
      },
      {
        heading: "Botni.Vision",
        paragraphs: [
          "Botni.Vision is a Montreal-based startup with a distributed team working in the media processing and video analysis space. Our first product, Rivr, enables users to search within video as easily as they search for video. Rivr turns any video into searchable experiences, making it easy to find your best moments, learn from your community, and share content like never before.",
          "Currently, we work with creators, streamers, influencers, gamers, and enterprises, creating new technology to produce better content faster. We make the impossible possible by removing the bottlenecks of running large-scale video-first advertising campaigns while also providing an accessible, fun, and engaging tool for end-users.",
          "Our founders are developers and focused on technology—every day we’re working on fun, challenging, and first-to-market technology.",
        ],
      },
      {
        heading: "The Role",
        paragraphs: [
          "In this role, you will play a crucial part in building and maintaining robust, functional code. This role encompasses the core workings of the product and is vital for ensuring the smooth operation and performance of our systems.",
        ],
      },
      {
        heading: "Responsibilities",
        bulletPoints: [
          "Help define the technical and product roadmap based on business and technical needs",
          "Lead multi-phase projects from concept to delivery",
          "Understand and explain trade-offs made in decisions and communicate them effectively",
          "Write clean, tested, and maintainable code",
          "Participate in code reviews to promote code quality and share knowledge",
          "Consider code reliability and business impact of various failures",
          "Participate in incidents when failures occur",
          "Share your knowledge and mentor others",
        ],
      },
      {
        heading: "Qualifications",
        bulletPoints: [
          "5+ years of experience with Python, Golang, Kotlin, C++ or equivalent languages",
          "Production experience with API design (REST, gRPC, etc.) and messaging systems (SQS, NATs, etc.) and patterns",
          "Strong data modeling skills with demonstrable competence using SQL (PostgreSQL, etc.) and no-SQL (Redis, DynamoDB, etc.) data stores and their tradeoffs",
          "Demonstrated track record of initiating and delivering projects",
          "BA/BS in Computer Science or a related technical field, or equivalent professional experience",
        ],
      },
      {
        heading: "Nice-to-haves",
        bulletPoints: [
          "Familiar with Python 3 and data/ML libraries like pandas, PyTorch, etc.",
          "Familiar with ORMs, especially SQLAlchemy",
          "Experience with Linux development or similar environments",
          "Familiar with AWS, GCP, Azure, or similar cloud providers",
          "Familiar with streaming media (HLS, DASH, etc.)",
          "Enthusiastic about computer gaming, streaming",
        ],
      },
      {
        heading: "What we offer",
        bulletPoints: [
          "Early stage equity",
          "30 days of PTO",
          "Flexible hybrid schedule",
          "Competitive group health benefits",
        ],
      },
    ],
    buttonHref: "mailto:careers@rivr.stream",
  },
  {
    title: "Sr. Machine Learning Developer/Engineer — 120-160k CAD + Equity",
    roles: [
      {
        heading: "Sr. Machine Learning Developer/Engineer — Rivr",
        subPoints: [
          "Hybrid, Full Time — within Montreal",
          "120-160k CAD + Equity",
        ],
      },
      {
        heading: "Botni.Vision",
        paragraphs: [
          "Botni.Vision is a Montreal-based startup with a distributed team working in the media processing and video analysis space. Our first product, Rivr, enables users to search within video as easily as they search for video. Rivr turns any video into searchable experiences, making it easy to find your best moments, learn from your community, and share content like never before.",
          "Currently, we work with creators, streamers, influencers, gamers, and enterprises, creating new technology to produce better content faster. We make the impossible possible by removing the bottlenecks of running large-scale video-first advertising campaigns while also providing an accessible, fun, and engaging tool for end-users.",
          "Our founders are developers and focused on technology—every day we’re working on fun, challenging, and first-to-market technology.",
        ],
      },
      {
        heading: "The Role",
        paragraphs: [
          "In this role, you will play a crucial part in building and maintaining robust, functional code. This role encompasses general backend feature work and is vital for ensuring the smooth operation and performance of our models and systems.",
        ],
      },
      {
        heading: "Qualifications",
        bulletPoints: [
          "5+ years of experience with Python, and ML libraries such as PyTorch, particularly for media information processing (vision, text, and audio)",
          "Experience with the deployment of ML models in production environments",
          "Experience with the training of ML models in distributed cloud infrastructure",
          "Skilled in utilizing messaging libraries and patterns such as NATs, gRPC, and REST",
          "Experience managing databases and datasets for the purpose of training production ML/AI models",
        ],
      },
      {
        heading: "Nice-to-haves",
        bulletPoints: [
          "Experience with Kubernetes, Docker containers, and microservice cloud deployment",
          "Experience with CUDA/Nvidia libraries",
          "2+ years of experience with C++",
          "Experience with Go, Rust, or similar frameworks",
          "Experience with Linux development or similar environments",
          "Open-source contributor actively working in ML, maintaining models, repositories",
          "Published papers or cited work",
          "Familiarity with and interest in software architecture and API design and documentation",
        ],
      },
      {
        heading: "What we offer",
        bulletPoints: [
          "Early stage equity",
          "30 days PTO",
          "Flexible hybrid schedule",
          "Competitive group health benefits",
        ],
      },
    ],
    buttonHref: "mailto:careers@rivr.stream",
  },
];

const Careers = () => {
  return (
    <Box w={"100%"} height={"100%"} position={"relative"} bg={"rivr.black"}>
      <Center pt={{ xl: 48, base: 32 }} pb={{ xl: 32, base: 24 }}>
        <VStack
          alignItems={"center"}
          w={"100%"}
          px={6}
          maxW={"1400px"}
          spacing={6}
        >
          <Heading as="h1" fontSize={"5xl"} textAlign={"center"}>
            Transparency, diversity, equity
          </Heading>
          <Text textAlign={"center"} color={"gray.300"} pb={24}>
            We’re a small team that leans on each other’s strengths and
            experiences every day. We’re building something new, which is
            exciting, and first-to-market, which can be challenging. We’re
            opinionated and believe that creating good products relies on mutual
            respect, a diverse team, and facing those challenges together. We
            believe in transparency and proactively working to make the
            technology industry more fair and equitable through progressive
            policy.
          </Text>
          <Heading as="h2" fontSize={"3xl"}>
            Join the team
          </Heading>
          <VStack
            alignItems={"center"}
            w={"100%"}
            bg={"whiteAlpha.100"}
            pt={6}
            pb={3}
            px={6}
            borderRadius={"xl"}
            spacing={6}
            borderWidth={1}
          >
            <Accordion allowToggle w={"100%"}>
              {accordionData.map((item, index) => (
                <AccordionItem
                  key={index}
                  bg="whiteAlpha.100"
                  borderRadius={"lg"}
                  borderWidth={1}
                  p={2}
                  mb={3}
                >
                  <AccordionButton
                    borderRadius={"lg"}
                    _hover={{ bg: "transparent" }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {item.title}
                    </Box>
                    <AccordionIcon boxSize={8} />
                  </AccordionButton>
                  <AccordionPanel pb={4} color={"gray.300"}>
                    {item.roles.map((role, roleIndex) => (
                      <VStack
                        key={roleIndex}
                        alignItems={"flex-start"}
                        spacing={0}
                        pt={6}
                      >
                        <Text
                          fontSize={"xl"}
                          fontWeight={"semibold"}
                          color={"gray.50"}
                          pb={3}
                        >
                          {role.heading}
                        </Text>
                        {role.subPoints && role.subPoints.length > 0 && (
                          <Box pb={2}>
                            {role.subPoints.map((point, pointIndex) => (
                              <Text key={pointIndex} fontSize={"lg"}>
                                {point}
                              </Text>
                            ))}
                          </Box>
                        )}
                        {role.paragraphs &&
                          role.paragraphs.length > 0 &&
                          role.paragraphs.map((para, paraIndex) => (
                            <Text key={paraIndex} pb={4}>
                              {para}
                            </Text>
                          ))}
                        {role.bulletPoints && role.bulletPoints.length > 0 && (
                          <UnorderedList pl={12} spacing={1} pb={4}>
                            {role.bulletPoints.map((bullet, bulletIndex) => (
                              <ListItem key={bulletIndex}>{bullet}</ListItem>
                            ))}
                          </UnorderedList>
                        )}
                      </VStack>
                    ))}
                    <Button
                      colorScheme="red"
                      bg="rivr.red"
                      color="white"
                      alignSelf={"center"}
                      w="100%"
                      as="a"
                      href={item.buttonHref}
                      size="lg"
                      mt={10}
                    >
                      Apply via email
                    </Button>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </VStack>
        </VStack>
      </Center>
    </Box>
  );
};

export default Careers;
