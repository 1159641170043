import {
  Box,
  VStack,
  Text,
  Heading,
  HStack,
  Avatar,
  Flex,
} from "@chakra-ui/react";
import Marquee from "react-fast-marquee";
import "./B2B.css";

import KK from "../../assets/kaiju-kittens.png";
import ChaoticNikki from "../../assets/creators/b2b/chaoticnikki.png";
import Dagligled3r from "../../assets/creators/b2b/dagligled3r.png";
import Fernwytch from "../../assets/creators/b2b/fernwytch.png";
import RagefulRiot from "../../assets/creators/b2b/ragefulriot.png";
import Raziel317 from "../../assets/creators/b2b/raziel317.png";
import Rizzlexo from "../../assets/creators/b2b/rizzlexo.png";
import Selya from "../../assets/creators/b2b/selya.png";
import Sl3minem from "../../assets/creators/b2b/sl3minem.png";
import Tokaji909 from "../../assets/creators/b2b/tokaji909.png";
import NorthernLotus from "../../assets/creators/b2b/northern-lotus.png";

const testimonials1 = [
  {
    avatar: Selya,
    quote:
      '"I usually struggle to create clips because of my schedule, but I just made 8 clips in a matter of minutes. I literally love this tool so much."',
    author: "Selya",
  },
  {
    avatar: Sl3minem,
    quote: '"I love this. It makes life so much easier to find good Moments."',
    author: "sl3minem",
  },
  {
    avatar: Dagligled3r,
    quote:
      '"This Rivr thing is sick! Can\'t wait to use it on all my streams."',
    author: "Dagligled3r",
  },
  {
    avatar: ChaoticNikki,
    quote:
      "\"I'm in love with this tool. I've gotten several good clips from it today ❤️\"",
    author: "Chaotic Nikki",
  },
  {
    avatar: Raziel317,
    quote:
      '"Rivr has totally changed how I deal with short form content. Pick your Moments, reformat to vertical, download, done, I needs more!"',
    author: "Raziel317",
  },
  {
    avatar: NorthernLotus,
    quote: '"I can not tell you how much I love the speech search. Oh my god!"',
    author: "Northern Lotus",
  },

  {
    avatar: Fernwytch,
    quote:
      '"Rivr is seriously such a good tool. I love being able to easily crop my camera and place it wherever."',
    author: "Fernwytch",
  },
  {
    avatar: Tokaji909,
    quote:
      '"Went through 6 VODs in no time and got some pretty cool Moments. I really like Rivr so far, saves me so much time."',
    author: "Tokaji909",
  },
  {
    avatar: Rizzlexo,
    quote:
      '"The speech search is a game changer for when you forget to make stream markers."',
    author: "rizzlexo",
  },
  {
    avatar: RagefulRiot,
    quote:
      '"I absolutely loved the opportunity to use Rivr for the last activation I was in. I am 100% appreciative to have been selected to use it. Game changer!"',
    author: "RagefulRiot",
  },
];

const TestimonialMarqueeBox = ({ avatar, quote, author }) => (
  <Flex
    bg={"whiteAlpha.100"}
    borderRadius={"md"}
    p={4}
    borderWidth={1}
    w={"24rem"}
    mx={-1}
    transition={"all 0.2s"}
    backdropFilter={"blur(2px)"}
    _hover={{
      bg: "whiteAlpha.200",
      borderColor: "rivr.red",
      shadow: "0 0 50px -12px rgba(228, 58, 25, 0.25);",
    }}
    cursor={"default"}
  >
    <VStack align={"start"}>
      <Avatar src={avatar} size="sm" borderWidth={1} borderRadius={"full"} />
      <Text fontSize={"sm"} sx={{ textWrap: "pretty" }}>
        {quote}
      </Text>
      <Text fontSize={"xs"} color={"gray.400"}>
        {author}
      </Text>
    </VStack>
  </Flex>
);

const B2BCreatorProgram = () => {
  return (
    <Box w={"100%"} pt={[32, 32, 64]}>
      <VStack
        w={"100%"}
        spacing={6}
        pb={[8, 8, 16]}
        maxW={"1400px"}
        mx={"auto"}
        px={6}
      >
        <Heading as={"h2"} textAlign={"center"}>
          Boost your creator program
        </Heading>
        <Text
          fontSize={"lg"}
          textAlign={"center"}
          color={"gray.300"}
          sx={{ textWrap: "pretty" }}
        >
          Enhance your creator program by giving creators access to
          industry-leading tools.
          <br />
          Creators who use Rivr share more content.
        </Text>
      </VStack>

      <VStack px={6} pb={6} spacing={4}>
        <Box
          w={"100%"}
          maxW={"800px"}
          bg={"whiteAlpha.100"}
          borderRadius={"md"}
          mx={"auto"}
          p={6}
          borderWidth={1}
          shadow={"xl"}
          transition={"all 0.2s"}
          backdropFilter={"blur(2px)"}
          _hover={{
            borderColor: "rivr.red",
            shadow: "0 10px 50px -12px rgba(228, 58, 25, 0.25);",
            bg: "whiteAlpha.200",
          }}
        >
          <HStack
            h={"100%"}
            w={"100%"}
            align={{ base: "start", md: "center" }}
            justify={"start"}
            flexDirection={{ base: "column", md: "row" }}
            gap={4}
          >
            <Avatar
              src={KK}
              boxSize={[16, 16, 20]}
              borderWidth={1}
              borderColor={"whiteAlpha.500"}
              borderRadius={"full"}
            />
            <VStack w={"100%"} align={"start"}>
              <Text sx={{ textWrap: "pretty" }}>
                "Rivr is the most beneficial perk I’ve ever seen from any
                creator program. This is why I plan on streaming Once Human
                more, because getting support like this shows they care about
                their creators and that we aren’t just a number."
              </Text>
              <Text fontSize={"sm"} color={"gray.400"}>
                Kaiju_Kittens — NetEase: Once Human Creator Program
              </Text>
            </VStack>
          </HStack>
        </Box>
      </VStack>
      <VStack spacing={-2} mb={1}>
        <Marquee autoFill={true} speed={40} pauseOnHover={true}>
          {testimonials1.map((testimonial, index) => (
            <TestimonialMarqueeBox key={index} {...testimonial} />
          ))}
        </Marquee>
      </VStack>
    </Box>
  );
};

export default B2BCreatorProgram;
