import { VStack, Divider, Link } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import BlogHeading from "../../../../components/Blog/BlogHeading";
import BlogDetails from "../../../../components/Blog/BlogDetails";
import BlogContent from "../../../../components/Blog/BlogContent";

import Marc from "../../../../assets/marc.jpg";

import AidanHeader from "../../../../assets/blog/news/aidan-announce/aidan-header.png";

const mode = "partnership"; // "caseStudy" or "creatorSpotlight" or "partnership"

const data = {
  pageUrl: "https://rivr.stream/blog/rivr-news-aidan-director-of-sales",
  heroImage: AidanHeader,
  //headlineBusiness: "DeejayKnight",
  headline: "Botni.Vision welcomes new Director of Sales, Aidan Kessell",
  subline:
    "Kessell, an accomplished professional in the marketing and influencer space, joins the Montreal-based tech startup to lead sales strategy and drive growth for Rivr, the company’s in-media search platform currently in a closed beta phase.",
  //tags: ["Primary Tag", "Secondary Tag", "Another Tag", "asldknalsknd"],
  author: "Marc Singer",
  authorImage: Marc,
  authorLink: "https://x.com/MarcFromRivr",
  date: "Jul 29, 2024",
  //contextCopy:
  //"We're highlighting creators using Rivr to share better content and make waves on the internet.",
  /*   socials: [
    { name: "Twitch", url: "https://www.twitch.tv/deejayknight" },
    {
      name: "Tiktok",
      url: "https://www.tiktok.com/@deejayknight",
      theme: "filled",
      iconSize: "20",
    },
    {
      name: "YouTube",
      url: "https://www.youtube.com/deejayknight",
      theme: "outline",
      iconSize: "26",
    },
    {
      name: "Twitter",
      url: "https://twitter.com/DeejayKnight",
      theme: "filled",
      iconSize: "20",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/deejayknight/",
      iconSize: "22",
    },
  ], */
  shareTitle:
    "Rivr - News: Botni.Vision welcomes new Director of Sales, Aidan Kessell",
  shareDesc: "Botni.Vision welcomes new Director of Sales, Aidan Kessell",
  shareImg: AidanHeader,
  type: "article",
};

const details = {
  businessType: "Test Business Type",
  locationCity: "City",
  locationCountry: "Country",
  tech: ["Technology", "Technology", "Technology"],
  ctaText: "CTA Text Here",
};

const content = [
  {
    type: "Spacer",
    spacingSmall: 10,
    spacingLarge: 10,
  },
  {
    type: "Text",
    content: (
      <>
        <Link
          href="https://botni.vision"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          Botni.Vision
        </Link>{" "}
        is excited to announce the appointment of Aidan Kessell as the new
        Director of Sales for{" "}
        <Link
          href="https://rivr.stream"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          Rivr
        </Link>
        , an in-media search platform enabling digital creators, livestreamers,
        and brands to identify and share the best moments in their videos.
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        Kessell brings extensive experience from his previous role at AFK
        Creators, a successful marketing and influencer agency primarily
        operating in the livestreaming and gaming space. At AFK Creators,
        Kessell played a pivotal role in developing and executing sales
        strategies that significantly boosted the agency's market presence and
        client portfolio.
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        “We're excited to welcome Aidan to our team," said Brandon Weinman-Tyre,
        CEO of Botni.Vision. “His deep understanding of the livestreaming and
        gaming industries, combined with his proven track record in sales, makes
        him the perfect fit to lead our sales initiatives. We worked with him
        many times in the past through some of our enterprise engagements, and
        we're confident that Aidan's expertise will be instrumental in expanding
        Rivr's reach and impact at this pivotal time in our business.”
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        In his new role, Kessell will focus on building and leading a dynamic
        sales team, developing strategic partnerships, and implementing
        innovative sales techniques to enhance Rivr's market presense. His
        appointment comes at a crucial time as Botni.Vision prepares for the
        broader release of Rivr, aimed at enabling digital creators,
        livestreamers, and brands to identify and share the best moments in
        their videos.
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        “I feel immensely privileged and fortunate to join Rivr; it will
        completely change marketers and creators' relationships with media.”
        said Kessell. “The ability to discover the most meaningful moments in
        media at scale and learn "why" they are meaningful is a huge turning
        point in influencer marketing. Excited is an understatement. I can't
        wait to see how I can impact Rivr's journey while having a front-row
        seat to witness how it will impact the industry.”
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        With extensive experience in sales and a strong background in the gaming
        and livestreaming industries, Kessell is ready to lead Botni.Vision's
        sales efforts. His strategic vision and leadership will play a crucial
        role in driving the company's growth and innovation.
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        For press inquiries or further information, please email{" "}
        <Link
          href="mailto:press@botni.vision"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          press@botni.vision
        </Link>
        .
      </>
    ),
  },
  {
    type: "Spacer",
    spacingSmall: 10,
    spacingLarge: 10,
  },
  {
    type: "Spacer",
    spacerText: "###",
    spacingSmall: 20,
    spacingLarge: 20,
  },
  {
    type: "Text",
    content: (
      <>
        <b>About Botni.Vision</b>
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        Botni.Vision is a technology company enabling human and artificial
        learning. We make Rivr, a revolutionary in-media search and content
        discovery platform trusted by creators and brands of all sizes. Rivr
        sees inside video and audio experiences, turning media into meaning and
        finding anything seen, heard, said, or felt. With the power to analyze
        at scale in real-time, identify the best Moments, and reformat output
        for any use case, Rivr transforms production workflows and makes it easy
        to create and share authentic content your audience will love. Visit{" "}
        <Link
          href="https://rivr.stream"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          rivr.stream
        </Link>{" "}
        for more information.
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        <b>About Aidan Kessell</b>
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        Aidan Kessell is a seasoned sales professional based in the United
        Kingdom with a strong background in the marketing and influencer space.
        Prior to joining Botni.Vision, he was a key player at AFK Creators,
        where he helped drive significant growth and success. His expertise in
        developing and executing sales strategies, coupled with his deep
        understanding of the livestreaming and gaming industries, makes him an
        invaluable asset to the Botni.Vision team.
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        <b>Contact</b>
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        Botni.Vision, Inc.
        <br />
        <Link
          href="mailto:press@botni.vision"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          press@botni.vision
        </Link>
        <br />
        <Link
          href="https://botni.vision"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          botni.vision
        </Link>
        <br />
        <Link
          href="https://rivr.stream"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          rivr.stream
        </Link>
      </>
    ),
  },
];

const NewsLowcoAnnounce = () => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{data.shareTitle}</title>
        <meta name="title" content={data.shareTitle} />
        <meta name="description" content={data.shareDesc} />
        <meta property="og:title" content={data.shareTitle} />
        <meta property="og:description" content={data.shareDesc} />
        <meta property="og:image" content={data.shareImg} />
        <meta property="og:url" content={data.pageUrl} />
        <meta property="og:type" content={data.type} />
        <meta name="twitter:title" content={data.shareTitle} />
        <meta name="twitter:description" content={data.shareDesc} />
        <meta name="twitter:image" content={data.shareImg} />
        <meta name="twitter:url" content={data.pageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@rivrstream" />
      </Helmet>
      <VStack justifyContent={"center"} bg="rivr.black">
        <VStack maxW={"1400px"} mx={6} pt={16} pb={24}>
          <BlogHeading
            mode={mode}
            pageUrl={data.pageUrl}
            headlineBusiness={data.headlineBusiness}
            headline={data.headline}
            heroImage={data.heroImage}
            subline={data.subline}
            author={data.author}
            authorImage={data.authorImage}
            authorLink={data.authorLink}
            contextCopy={data.contextCopy}
            date={data.date}
            tags={data.tags}
            socials={data.socials}
            shareTitle={data.shareTitle}
          />
          {mode === "creatorSpotlight" || mode === "partnership" ? (
            <Divider bg={"gray.400"} mb={2} />
          ) : null}
          {mode === "caseStudy" ? (
            <BlogDetails
              businessType={details.businessType}
              locationCity={details.locationCity}
              locationCountry={details.locationCountry}
              tech={details.tech}
              ctaText={details.ctaText}
            />
          ) : null}
          <BlogContent contentArray={content} />
        </VStack>
      </VStack>
    </>
  );
};

export default NewsLowcoAnnounce;
