import {
  Box,
  Flex,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Link,
  UnorderedList,
  ListItem,
  VStack,
  HStack,
  Button,
} from "@chakra-ui/react";
import * as amplitude from "@amplitude/analytics-browser";

const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
  amplitude.track(e, properties);
};

const B2BFaq = () => {
  const faqItems = [
    {
      question: "What is Rivr?",
      answer:
        "Rivr is a revolutionary platform for searching within media, discovering your best Moments, and turning them into content your audience will love. Rivr also enables the ability to search transcripts and chat across any number of videos to find brand mentions, call outs, contractual obligations, user frustrations, and more. ",
    },
    {
      question: "How does Rivr work?",
      answer: (
        <>
          <VStack alignItems={"flex-start"} spacing={4}>
            <Text>
              Rivr uses advanced technology to translate media into data. That
              data helps you quickly identify interesting Moments and learn what
              your audiences find most engaging.
            </Text>
            <Text>
              Once you’ve found great stuff, Rivr helps you optimize your
              content production workflow and make the most of every Moment.
              With built-in reformatting tools and integrations, you can stop
              spending hours scrubbing and start sharing amazing content and
              understanding your audiences – fast.
            </Text>
          </VStack>
        </>
      ),
    },
    {
      question: "Is Rivr AI?",
      answer: (
        <>
          <VStack alignItems={"flex-start"} spacing={4}>
            <Text>
              Yes, where it makes sense, and no where it doesn't. AI is a tool,
              not the entire solution, and Rivr isn't an AI autoclipper.
            </Text>
            <Text>
              Rather than telling you what constitutes good or bad content, or
              limiting you to a specific set of predetermined Moments, Rivr
              provides meaningful data to help you make quick and informed
              decisions for yourself.
            </Text>
          </VStack>
        </>
      ),
    },
    {
      question: "What platforms does Rivr work with?",
      answer: (
        <>
          <Text>
            For closed-beta users, Rivr currently supports media hosted on
            Twitch and YouTube. If you have media hosted elsewhere and want to
            learn how Rivr can help your business,{" "}
            <Link
              isExternal
              href="mailto:sales@rivr.stream"
              textDecoration={"underline"}
              _hover={{ color: "gray.50" }}
            >
              get in touch
            </Link>
            . Rivr can reformat content to be suitable for TikTok, YouTube
            Shorts, and Instagram Reels.
          </Text>
        </>
      ),
    },
    {
      question: "What type of content does Rivr work best on?",
      answer:
        "Rivr can effectively analyze many types of content – gaming, podcasts, live events, workshops, interviews, and more. Media with live-chat channels works best, allowing you to discover standout Moments based on community response and engagement.",
    },
    {
      question: "For gaming, what does Rivr support?",
      answer: (
        <>
          <Text>
            Rivr is the <strong>only</strong> platform that supports all games,
            from retro to next-gen, cozy to speedrunning. Play what you want to
            play, or don’t play a game at all – either way, Rivr will help you
            discover the best Moments in your media.
          </Text>
        </>
      ),
    },
    {
      question: "Can Rivr locate product placement?",
      answer:
        "Yes, Rivr can identify product placements within video content. Our platform uses advanced video and speech recognition to track when and where your products appear in streams, videos, or other media.",
    },
    {
      question: "How can Rivr help with my game launch?",
      answer:
        "Rivr simplifies monitoring your game launch from day one. Discover what creators and their communities are discussing, identify any issues, and understand which aspects of your game are resonating most with players. Rivr transforms how influencer marketing is approached, by surfacing the best content across your game launch no matter the size of the creator.",
    },
    {
      question: "How can Rivr locate bugs and issues for my public beta test?",
      answer:
        "Rivr pinpoints moments of frustration and negative sentiment, while scanning chat and transcriptions for mentions of bugs, crashes, and other issues.",
    },
    {
      question: "How does Rivr increase the scale of my influencer campaigns?",
      answer:
        "Rivr automates the analysis and management of vast amounts of content, giving brands a comprehensive view of top moments and user-generated content.",
    },
    {
      question: "How can Rivr be of value to my creator program?",
      answer:
        "Creators thrive when they can grow alongside the brands they love. By offering Rivr, you equip creators with a vital tool for transforming livestreams into far-reaching short-form content. Creators value partnerships that support their growth, and with Rivr, they can produce higher quality content more frequently, boosting their opportunities for success.",
    },
    {
      question:
        "How can Rivr get creators to share more social content from my game?",
      answer:
        "Rivr encourages creators to share more content by providing them with easy access to their best moments. Our platform highlights and curates key moments that creators can quickly download and share across their social channels. This not only boosts content output but also amplifies your game’s presence across social media.",
    },
    {
      question: "How can Rivr ensure brand safety in user-generated content?",
      answer:
        "Rivr lets you scan for keywords in both creator and community content, making it easy to identify expletive language or negative sentiment.",
    },
    {
      question:
        "What kind of support and onboarding does Rivr offer for new clients?",
      answer:
        "We’re committed to your campaign’s success. We provide hands-on support through every step, offering team demos and a private real-time communication channel for any questions.",
    },
    {
      question: "Rivr isn’t working. Where can I get help?",
      answer: (
        <>
          <VStack spacing={4} alignItems={"flex-start"}>
            <Text>Rivr has several support channels:</Text>
            <UnorderedList pl={8} pb={2}>
              <ListItem>
                <strong>Live chat support</strong> – available within the{" "}
                <Link
                  isExternal
                  href="https://app.rivr.stream"
                  textDecoration={"underline"}
                  _hover={{ color: "gray.50" }}
                >
                  Rivr platform
                </Link>
                .
              </ListItem>
              <ListItem>
                <strong>Discord</strong> – Check with your account manager for
                access to our private brand Discord
              </ListItem>
              <ListItem>
                <strong>Email</strong> – Send a message to{" "}
                <Link
                  isExternal
                  href="mailto:support@rivr.stream"
                  textDecoration={"underline"}
                  _hover={{ color: "gray.50" }}
                >
                  support@rivr.stream
                </Link>
              </ListItem>
              <ListItem>
                <strong>Twitter/X</strong> – Send a DM to{" "}
                <Link
                  isExternal
                  href="https://x.com/rivrstream"
                  textDecoration={"underline"}
                  _hover={{ color: "gray.50" }}
                >
                  @rivrstream
                </Link>
              </ListItem>
            </UnorderedList>
            <HStack>
              <Button
                colorScheme="blue"
                as="a"
                href="https://x.com/rivrstream"
                size="sm"
              >
                Follow on Twitter/X
              </Button>
            </HStack>
          </VStack>
        </>
      ),
    },
  ];

  return (
    <Box position={"relative"} w={"100%"} overflow={"hidden"}>
      <Flex
        w="100%"
        maxW={"1400px"}
        px={"6"}
        pb={[32, 32, 64]}
        h="100%"
        mx="auto"
      >
        <VStack spacing={12} w={"100%"}>
          <VStack justifyContent={"center"} w={"100%"} spacing={5}>
            <div
              id="faq"
              style={{
                position: "relative",
                top: "-104px",
                visibility: "hidden",
              }}
            ></div>
            <Heading
              as={"h2"}
              size={"lg"}
              color={"gray.50"}
              textAlign={"center"}
            >
              Frequently Asked Questions
            </Heading>
          </VStack>
          <Accordion allowToggle w={"100%"}>
            {faqItems.map((item, index) => (
              <AccordionItem key={index} py={4}>
                <AccordionButton
                  _hover={{ bg: "whiteAlpha.200" }}
                  _expanded={{ bg: "rivr.red", color: "white" }}
                  borderRadius={"md"}
                  onClick={() =>
                    handleAmplitudeTrack("FAQ Accordion Clicked", {
                      question: item.question.split(" ").slice(0, 3).join(" "),
                      location: "B2B",
                    })
                  }
                >
                  <Box as={"span"} flex={"1"} textAlign={"left"}>
                    <Text
                      fontSize={{ xl: "xl", lg: "xl", md: "md", sm: "md" }}
                      fontWeight={"bold"}
                      color="gray.50"
                    >
                      {item.question}
                    </Text>
                  </Box>
                  <AccordionIcon boxSize={8} />
                </AccordionButton>
                <AccordionPanel mt={3} pb={2} color="gray.300">
                  {item.answer}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </VStack>
      </Flex>
    </Box>
  );
};

export default B2BFaq;
