import { Box, Button, Flex, Heading, VStack } from "@chakra-ui/react";
import ImageWithHoverCursor from "./ImageWithHoverCursor";
import { ChevronRightIcon } from "@chakra-ui/icons";
import * as amplitude from "@amplitude/analytics-browser";

const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
  amplitude.track(e, properties);
};

const HeroSectionAlt = () => {
  return (
    <Box position="relative" w="100%" overflow="hidden">
      <VStack
        w="100%"
        maxW={"100%"}
        px={6}
        pt={[32, 32, 64]}
        pb={8}
        h="100%"
        justifyContent="center"
        mx="auto"
        zIndex={1}
        spacing={8}
      >
        <Heading
          as={"h1"}
          size={["xl", "2xl", "4xl"]}
          color={"gray.50"}
          textAlign={"center"}
          zIndex={100}
          sx={{ textWrap: "balance" }}
          lineHeight={"1.2 !important"}
        >
          <span
            style={{
              color: "#e43a19",
              fontStyle: "italic",
              marginRight: "1.3%",
            }}
          >
            Rivr
          </span>
          lutionizing Content Creation
        </Heading>
        <Heading
          as="h2"
          size={{ xl: "md", lg: "md", md: "md", sm: "sm" }}
          color="gray.300"
          fontWeight={"medium"}
          mt={8}
          textAlign="center"
          lineHeight="1.5"
          zIndex={100}
          sx={{ textWrap: "pretty" }}
        >
          Turn video into searchable experiences.
          <br />
          Discover and share your best Moments.
        </Heading>
        <Button
          as={"a"}
          href={"https://app.rivr.stream"}
          rightIcon={<ChevronRightIcon />}
          aria-label={"Join early access"}
          colorScheme={"red"}
          target={"_blank"}
          bg={"rivr.red"}
          color={"gray.50"}
          onClick={() =>
            handleAmplitudeTrack("Join Early Access Clicked", {
              location: "Hero",
            })
          }
        >
          Join early access
        </Button>
      </VStack>
      <Flex
        w={"100%"}
        pt={4}
        justify={"center"}
        align={"center"}
        px={6}
        pb={[32, 32, 64]}
      >
        <ImageWithHoverCursor />
      </Flex>
    </Box>
  );
};

export default HeroSectionAlt;
