import { HStack, VStack, Heading, Text } from "@chakra-ui/react";

const BlogQuote = ({ styleType, quote, personName, personTitle }) => {
  if (styleType === "large") {
    return (
      <VStack py={{ lg: 16, base: 10 }} spacing={0} alignItems={"stretch"}>
        <Text
          lineHeight={"1rem"}
          textAlign={"left"}
          fontSize={{ lg: "8xl", base: "6xl" }}
          color={"rivr.red"}
          pointerEvents={"none"}
        >
          “
        </Text>
        <Heading
          pb={4}
          zIndex={3}
          as={"h3"}
          fontSize={{ lg: "4xl", base: "3xl" }}
          fontWeight={"semibold"}
        >
          {quote}
        </Heading>
        <HStack fontWeight={"medium"}>
          {personName && <Text>{personName}</Text>}
          {personTitle && <Text>—</Text>}
          {personTitle && <Text color="gray.400">{personTitle}</Text>}
        </HStack>

        <Text
          lineHeight={0}
          transform={"scale(5) translateY(.6rem)"}
          transformOrigin={"bottom right"}
          textAlign={"right"}
          fontSize={{ lg: "7xl", base: "5xl" }}
          color={"rivr.red"}
          fontWeight={"extrabold"}
          zIndex={2}
          opacity={"33%"}
          pointerEvents={"none"}
        >
          ”
        </Text>
      </VStack>
    );
  }
  if (styleType === "small") {
    return (
      <VStack
        py={{ lg: 10, base: 6 }}
        spacing={0}
        alignItems={"stretch"}
        ml={{
          xl: "35rem !important",
          lg: "33rem !important",
          md: "3rem !important",
          sm: "2rem !important",
        }}
        mr={{
          xl: "11rem !important",
          lg: "9rem !important",
          md: "3rem !important",
          sm: "3rem !important",
        }}
      >
        <Heading
          zIndex={3}
          as={"h3"}
          fontSize={{ lg: "2xl", base: "xl" }}
          fontWeight={"medium"}
          borderLeftColor={"rivr.red"}
          borderLeftWidth={2}
          pl={4}
          py={2}
        >
          “{quote}”
        </Heading>
        <HStack fontWeight={"medium"}>
          {personName && <Text>{personName}</Text>}
          {personTitle && <Text>—</Text>}
          {personTitle && <Text color="gray.400">{personTitle}</Text>}
        </HStack>
      </VStack>
    );
  }

  return null;
};

export default BlogQuote;
