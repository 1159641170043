const DiscordIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 -28.5 256 256"
  >
    <path
      d="M216.856 16.597A208.5 208.5 0 0 0 164.042 0a154.86 154.86 0 0 0-6.765 14.046c-19.692-2.961-39.203-2.961-58.533 0C96.911 9.645 94.193 4.113 91.897 0a207.81 207.81 0 0 0-52.855 16.638C5.618 67.147-3.443 116.401 1.087 164.956c22.169 16.555 43.653 26.612 64.775 33.193 5.215-7.177 9.866-14.807 13.873-22.848-7.631-2.9-14.94-6.478-21.846-10.632 1.832-1.357 3.624-2.777 5.356-4.237 42.123 19.702 87.89 19.702 129.51 0a131.66 131.66 0 0 0 5.356 4.237 136.07 136.07 0 0 1-21.887 10.653c4.007 8.02 8.638 15.671 13.873 22.848 21.142-6.581 42.646-16.637 64.815-33.213 5.316-56.288-9.081-105.089-38.056-148.359zM85.474 135.095c-12.645 0-23.015-11.805-23.015-26.18s10.148-26.2 23.015-26.2 23.236 11.804 23.015 26.2c.02 14.375-10.148 26.18-23.015 26.18zm85.051 0c-12.645 0-23.015-11.805-23.015-26.18s10.148-26.2 23.015-26.2 23.236 11.804 23.015 26.2c0 14.375-10.148 26.18-23.015 26.18z"
      fill="#FFF"
    />
  </svg>
);

export default DiscordIcon;
