import {
  Box,
  Heading,
  VStack,
  HStack,
  Text,
  Image,
  Link,
  Button,
} from "@chakra-ui/react";
import Youtube from "../../assets/icons/youtube.svg";
import Twitter from "../../assets/x-icon.svg";
import Tiktok from "../../assets/icons/tiktok.svg";
import Twitch from "../../assets/icons/twitch.svg";
import Instagram from "../../assets/icons/instagram.svg";
import Marquee from "react-fast-marquee";
import NateSherriff from "../../assets/creators/nate-sherriff.png";
import RightURKen from "../../assets/creators/right-u-r-ken.png";
import Phuroggie from "../../assets/creators/phuroggie.png";
import MikeTheBard from "../../assets/creators/mike-the-bard.png";
import Deejay from "../../assets/creators/deejay.png";
import DanielTheDemon from "../../assets/creators/danielthedemon.png";
import Dds from "../../assets/creators/dds.png";
import Karbonn from "../../assets/creators/karbonn.png";
import PleasantlyTwstd from "../../assets/creators/pt.png";
import Thormungandr from "../../assets/creators/thormungandr.png";
import CaptainDylan from "../../assets/creators/captain-dylan.png";
import Felix from "../../assets/creators/felix.png";
import Mokonoise from "../../assets/creators/mokonoise.png";
import { ChevronRightIcon } from "@chakra-ui/icons";
import * as amplitude from "@amplitude/analytics-browser";

const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
  amplitude.track(e, properties);
};

const testimonials = [
  {
    text: '\n"Rivr is a total game changer. With Signal Search, I can find and deliver the best moments from my VODs effortlessly. Its interface is user-friendly and it integrates seamlessly into my workflow, saving me time and effort and allowing me to focus on what truly matters: delivering top-notch content to my community."\n',
    name: "DanielTheDemon",
    img: DanielTheDemon,
    socialLinks: [
      {
        label: "Twitch",
        icon: Twitch,
        boxSize: 5,
        href: "https://twitch.tv/danielthedemon",
      },
      {
        label: "TikTok",
        icon: Tiktok,
        boxSize: 4,
        href: "https://tiktok.com/@danielthedemon",
      },
      {
        label: "Youtube",
        icon: Youtube,
        boxSize: 6,
        href: "https://youtube.com/danielthedemon",
      },
    ],
  },
  {
    text: '\n"As a part-time creator, finding time to make content can be tough. Fortunately, Rivr has already saved me countless hours of searching for key moments in my livestreams. Being able to use Rivr to quickly find and highlight epic or funny moments has been a lifesaver."\n',
    name: "Nate Sherriff",
    img: NateSherriff,
    socialLinks: [
      {
        label: "Youtube",
        icon: Youtube,
        boxSize: 6,
        href: "https://youtube.com/natesherriff",
      },
      {
        label: "Twitter",
        icon: Twitter,
        boxSize: 4,
        href: "https://x.com/itsnatesherriff",
      },
      {
        label: "Twitch",
        icon: Twitch,
        boxSize: 5,
        href: "https://twitch.tv/natesherriff",
      },
      {
        label: "TikTok",
        icon: Tiktok,
        boxSize: 4,
        href: "https://tiktok.com/@itsnatesherriff",
      },
    ],
  },
  {
    text: '\n"Wearing multiple hats as a one-person team has many challenges – particularly time. Rivr gives that back to me in abundance by showing me points of interest in my VODs. There are moments I may miss during livestreams that my community found entertaining, and Rivr ensures I never lose them."\n',
    name: "Right_U_R_Ken",
    img: RightURKen,
    socialLinks: [
      {
        label: "Twitch",
        icon: Twitch,
        boxSize: 5,
        href: "https://twitch.tv/right_u_r_ken",
      },
      {
        label: "TikTok",
        icon: Tiktok,
        boxSize: 4,
        href: "https://tiktok.com/@right_u_r_ken",
      },
      {
        label: "Youtube",
        icon: Youtube,
        boxSize: 6,
        href: "https://youtube.com/@kenbucks",
      },
      {
        label: "Twitter",
        icon: Twitter,
        boxSize: 4,
        href: "https://x.com/twitch_u_r_ken",
      },
    ],
  },
  {
    text: '\n"Scrubbing through a month-old, 12-hour VOD with no memory of what happened is very tiring. Rivr works so well. After almost 11 years of streaming, to have this type of data to analyze and exploit is great."\n',
    name: "MikeTheBard",
    img: MikeTheBard,
    socialLinks: [
      {
        label: "Twitch",
        icon: Twitch,
        boxSize: 5,
        href: "https://twitch.tv/MikeTheBard",
      },
      {
        label: "Twitter",
        icon: Twitter,
        boxSize: 4,
        href: "https://x.com/MikeTheBard",
      },
    ],
  },
  {
    text: "\n\"I've tried all the AI autoclippers out there and I'mma keep it a buck, they are mid. AI doesn't know what my community loves, but Rivr does. This platform literally makes me the most effective creator I have been, and helps me in building my frog army no matter what game I’m playing.\"\n",
    name: "Phuroggie",
    img: Phuroggie,
    socialLinks: [
      {
        label: "TikTok",
        icon: Tiktok,
        boxSize: 4,
        href: "https://tiktok.com/@phuroggie",
      },
      {
        label: "Youtube",
        icon: Youtube,
        boxSize: 6,
        href: "https://youtube.com/@PhuroggieShorts",
      },
      {
        label: "Twitter",
        icon: Twitter,
        boxSize: 4,
        href: "https://x.com/Phuroggie",
      },
    ],
  },
  {
    text: '\n"It takes a massive amount of time to scrub through VODs. Rivr allows me to quickly go through and find the most interesting parts of my stream in minutes, giving those moments a chance to live on and grow."\n',
    name: "Deejay Knight",
    img: Deejay,
    socialLinks: [
      {
        label: "Twitch",
        icon: Twitch,
        boxSize: 5,
        href: "https://twitch.tv/deejayknight",
      },
      {
        label: "TikTok",
        icon: Tiktok,
        boxSize: 4,
        href: "https://tiktok.com/@deejayknight",
      },
      {
        label: "Instagram",
        icon: Instagram,
        boxSize: 6,
        href: "https://instagram.com/deejayknight/",
      },
    ],
  },
  {
    text: '\n"Rivr is the tool I never knew I needed. It’s incredibly easy to use and what it does is like magic. Hype and Chat Response Signals help me see moments and trends without having to spend hours scrubbing. Balancing a full-time career with part-time streaming, Rivr returns the one thing I can’t buy with money: time."\n',
    name: "Karbonn",
    img: Karbonn,
    socialLinks: [
      {
        label: "Twitch",
        icon: Twitch,
        boxSize: 5,
        href: "https://twitch.tv/karbonn",
      },
      {
        label: "Twitter",
        icon: Twitter,
        boxSize: 4,
        href: "https://x.com/Karbon4444",
      },
    ],
  },
  {
    text: '\n"Rivr is a great way to find the moments from my streams that are worth monetizing. It makes it easy to pinpoint critical moments to capitalize on across various platforms. Combining Rivr with my existing video editing skills gives me a clear advantage."\n',
    name: "DDS618",
    img: Dds,
    socialLinks: [
      {
        label: "Twitch",
        icon: Twitch,
        boxSize: 5,
        href: "http://twitch.tv/DDS618",
      },
      {
        label: "Youtube",
        icon: Youtube,
        boxSize: 6,
        href: "http://youtube.com/@DDS618",
      },
      {
        label: "TikTok",
        icon: Tiktok,
        boxSize: 4,
        href: "http://tiktok.com/@dds618",
      },
      {
        label: "Twitter",
        icon: Twitter,
        boxSize: 4,
        href: "http://x.com/DDS618_",
      },
    ],
  },
  {
    text: "\n\"Rivr has been a massive boon to help capture and highlight key moments from my streams, boosting my ability to share and post my content across multiple platforms.  The team is knowledgeable, helpful, and keeps us informed (and excited) about new features that provide the 'missing piece' to really elevate my content!\"\n",
    name: "PleasantlyTwstd",
    img: PleasantlyTwstd,
    socialLinks: [
      {
        label: "Twitch",
        icon: Twitch,
        boxSize: 5,
        href: "https://twitch.tv/pleasantlytwstd",
      },
      {
        label: "Twitter",
        icon: Twitter,
        boxSize: 4,
        href: "https://x.com/OhItMeNessaB",
      },
      {
        label: "Instagram",
        icon: Instagram,
        boxSize: 6,
        href: "https://instagram.com/pleasantlytwstd/",
      },
    ],
  },
  {
    text: '\n"Rivr has allowed me to have more time at night by cutting down on the post-stream minutiae, providing me with an easy way to access chat transcripts and identify where the interesting parts of my stream are occurring, all packaged into a convenient video manager/clip editor."',
    name: "Thormungandr",
    img: Thormungandr,
    socialLinks: [
      {
        label: "Twitch",
        icon: Twitch,
        boxSize: 5,
        href: "https://twitch.tv/thormungandr",
      },
      {
        label: "Twitter",
        icon: Twitter,
        boxSize: 4,
        href: "https://x.com/Thormungandr",
      },
      {
        label: "YouTube",
        icon: Youtube,
        boxSize: 6,
        href: "https://youtube.com/channel/UCNCCJ7a3BNuLAvOu-PEDr2Q",
      },
    ],
  },
  {
    text: '\n"Rivr has been an incredible addition to my content toolkit. The timeline and signal data makes the process of reviewing an entire VOD and finding great moments so much easier. Plus I can edit for TikTok and YouTube right in the app. Huge W."',
    name: "Captain Dylan",
    img: CaptainDylan,
    socialLinks: [
      {
        label: "Twitch",
        icon: Twitch,
        boxSize: 5,
        href: "https://twitch.tv/CaptainDylan",
      },
      {
        label: "YouTube",
        icon: Youtube,
        boxSize: 6,
        href: "https://youtube.com/theorderofdylan",
      },
      {
        label: "Twitter",
        icon: Twitter,
        boxSize: 4,
        href: "https://x.com/CaptainDylanTV",
      },
      {
        label: "TikTok",
        icon: Tiktok,
        boxSize: 4,
        href: "https://tiktok.com/@captaindylantv",
      },
    ],
  },
  {
    text: '\n"Rivr is going to be one of the most clutch tools for streamers to use. It was so helpful as a small-time creator to find great clipable moments as well as to learn how my community responds to certain aspects of my stream. Rivr is going to be a staple for streamers of all sizes."',
    name: "Psychotic_Felix",
    img: Felix,
    socialLinks: [
      {
        label: "Twitch",
        icon: Twitch,
        boxSize: 5,
        href: "https://twitch.tv/psychotic_felix",
      },
      {
        label: "Twitter",
        icon: Twitter,
        boxSize: 4,
        href: "https://x.com/PsychoticFelix",
      },
    ],
  },
  {
    text: '\n"I\'ve always dreaded looking through my VODs as I tend to stream for long hours. Rivr has changed that! There are so many great features that help me find things like when my chat goes crazy. Find great moments, reformat them to vertical, done. Everything in one place!"',
    name: "Mokonoise",
    img: Mokonoise,
    socialLinks: [
      {
        label: "Twitch",
        icon: Twitch,
        boxSize: 5,
        href: "https://www.twitch.tv/mokonoise",
      },
      {
        label: "TikTok",
        icon: Tiktok,
        boxSize: 4,
        href: "https://www.tiktok.com/@mokonoise",
      },
      {
        label: "Twitter",
        icon: Twitter,
        boxSize: 4,
        href: "https://x.com/Mokonoise",
      },
      {
        label: "YouTube",
        icon: Youtube,
        boxSize: 6,
        href: "https://youtube.com/mokonoise",
      },
    ],
  },
];

const TestimonialMarqueeBox = ({ img, text, name, socialLinks }) => (
  <Box
    bg={"whiteAlpha.100"}
    borderRadius={"md"}
    p={4}
    borderWidth={1}
    w={"24rem"}
    mx={-1}
    transition={"all 0.2s"}
    backdropFilter={"blur(2px)"}
    _hover={{
      bg: "whiteAlpha.200",
      borderColor: "rivr.red",
      shadow: "0 15px 50px -12px rgba(228, 58, 25, 0.25);",
    }}
    cursor={"default"}
  >
    <VStack align={"start"}>
      <HStack alignItems={"center"} justifyContent={"flex-start"} spacing={4}>
        <Image
          src={img}
          objectFit="cover"
          boxSize={12}
          borderRadius="full"
          alt={name}
        />
        <VStack alignItems={"flex-start"} spacing={1}>
          <Text>{name}</Text>
          <HStack spacing={2}>
            {socialLinks.map((link, i) => (
              <Link href={link.href} target="_blank" key={i}>
                <Image
                  key={i}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={link.label}
                  src={link.icon}
                  boxSize={link.boxSize}
                />
              </Link>
            ))}
          </HStack>
        </VStack>
      </HStack>
      <Text fontSize={"sm"} sx={{ textWrap: "pretty" }}>
        {text}
      </Text>
    </VStack>
  </Box>
);

const Creators = () => {
  return (
    <Box w={"100%"} pt={[32, 32, 64]}>
      <div
        id="creators"
        style={{
          position: "relative",
          top: "-104px",
          visibility: "hidden",
        }}
      ></div>
      <Heading as="h2" size="lg" color="gray.50" textAlign="center" pb={4}>
        Empowering creators along their journey
      </Heading>
      <VStack spacing={-2}>
        <Marquee autoFill={true} speed={25} pauseOnHover={true}>
          {testimonials.map((testimonial, index) => (
            <TestimonialMarqueeBox key={index} {...testimonial} />
          ))}
        </Marquee>
        <Marquee
          autoFill={true}
          direction={"right"}
          speed={25}
          pauseOnHover={true}
        >
          {testimonials.map((testimonial, index) => (
            <TestimonialMarqueeBox key={index} {...testimonial} />
          ))}
        </Marquee>
      </VStack>
      <VStack spacing={8} pt={16}>
        <Heading
          as="h2"
          size={{ xl: "md", lg: "md", md: "md", sm: "sm" }}
          color="gray.300"
          fontWeight={"medium"}
          mt={8}
          textAlign="center"
          lineHeight="1.5"
          zIndex={100}
          sx={{ textWrap: "pretty" }}
        >
          Ready to start your journey?
          <br />
          Join early access for free, today.
        </Heading>
        <Button
          as={"a"}
          alignSelf={"center"}
          href={"https://app.rivr.stream"}
          rightIcon={<ChevronRightIcon />}
          aria-label={"Join early access"}
          colorScheme={"red"}
          target={"_blank"}
          bg={"rivr.red"}
          color={"gray.50"}
          onClick={() =>
            handleAmplitudeTrack("Join Early Access Clicked", {
              location: "Creator Testimonials",
            })
          }
        >
          Join early access
        </Button>
      </VStack>
    </Box>
  );
};

export default Creators;
