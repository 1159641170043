import { Box, Flex, HStack, Image, Text } from "@chakra-ui/react";
import ImageModal from "../ImageModal/imagemodal";

const BlogImage = ({ styleType, images, caption }) => {
  const commonProps = {
    borderRadius: "3xl",
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "rivr.red",
  };

  if (styleType === "single") {
    return (
      <Flex
        ml={{ lg: "32rem !important", base: null }}
        pb={1}
        flexDir={"column"}
      >
        <ImageModal
          {...commonProps}
          src={images[0]}
          alt={caption}
          m={1}
          w={"100%"}
        />
        <Box py={4} alignSelf={"center"} w={"100%"}>
          <Text
            color={"gray.400"}
            textAlign={"left"}
            sx={{ textWrap: "balance" }}
          >
            {caption}
          </Text>
        </Box>
      </Flex>
    );
  }

  if (styleType === "full") {
    return (
      <Flex>
        <Image {...commonProps} w={"100%"} src={images[0]} m={1} />
      </Flex>
    );
  }

  if (styleType === "dual") {
    return (
      <Flex ml={{ lg: "32rem !important", base: null }}>
        <HStack w="100%" justifyContent={"center"} p={1}>
          <Image {...commonProps} w={"50%"} src={images[0]} m={1} />
          <Image {...commonProps} w={"50%"} src={images[1]} m={1} />
        </HStack>
      </Flex>
    );
  }

  return null;
};

export default BlogImage;
