import {
  Box,
  VStack,
  SimpleGrid,
  Icon,
  Text,
  useBreakpointValue,
  Heading,
  HStack,
  Button,
} from "@chakra-ui/react";
import {
  Calendar,
  Crown,
  Endocrine,
  EveryUser,
  Heartbeat,
  KeyOne,
  List,
  MessageSearch,
  PositiveDynamics,
  ScreenRotation,
  Send,
} from "@icon-park/react";
import * as amplitude from "@amplitude/analytics-browser";

const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
  amplitude.track(e, properties);
};

const features = [
  {
    icon: Heartbeat,
    title: "Sentiment Analysis",
    description:
      "Surface positive and negative commentary from creators and their communities.",
  },
  {
    icon: MessageSearch,
    title: "VOD Transcription",
    description:
      "Searchable, full VOD transcripts across entire campaigns, in over 100 languages.",
  },
  {
    icon: Endocrine,
    title: "Hype Detection",
    description:
      "Discover the most exciting moments that are sure to get a reaction.",
  },
  {
    icon: ScreenRotation,
    title: "Vertical Clip Reformatter",
    description: "Easily reformat content for vertical-first social platforms.",
  },
  {
    icon: List,
    title: "Unlimited Channels",
    description:
      "Track and analyze unlimited channels across any size campaign.",
  },
  {
    icon: EveryUser,
    title: "Multi-seat Organizations",
    description:
      "Invite team members to manage campaigns and content in your organization.",
  },
  {
    icon: Crown,
    title: "Professional Services",
    description:
      "From creator sourcing to content packages, access our professional services and partner network.",
  },
  {
    icon: PositiveDynamics,
    title: "Campaign Reporting",
    description: "Understand the performance of your campaign, at a glance.",
  },
  {
    icon: KeyOne,
    title: "Creator Keys",
    description:
      "Elevate your creator program by providing access to best-in-class tools.",
  },
];

const B2BFeatures = () => {
  const columns = useBreakpointValue({ base: 1, sm: 1, md: 2, lg: 3 });

  return (
    <Box w="100%" px={6} maxW={"1400px"} mx={"auto"} pt={[32, 32, 64]}>
      <VStack w="100%" spacing={6} pb={[8, 8, 16]}>
        <Heading as={"h2"} textAlign={"center"}>
          Features
        </Heading>
        <Text
          fontSize={"lg"}
          textAlign={"center"}
          color={"gray.300"}
          sx={{ textWrap: "pretty" }}
        >
          Everything you need to take your campaigns to the next level.
        </Text>
      </VStack>
      <SimpleGrid
        columns={columns}
        spacing={4}
        alignItems={"stretch"}
        justifyItems={"stretch"}
      >
        {features.map((feature, index) => (
          <VStack
            key={index}
            spacing={4}
            align={"start"}
            bg={"whiteAlpha.100"}
            p={6}
            borderRadius={"md"}
            shadow={"base"}
            transition={"all 0.2s"}
            borderWidth={1}
            backdropFilter={"blur(2px)"}
            _hover={{
              transform: "translateY(-1px)",
              bg: "whiteAlpha.200",
              borderColor: "rivr.red",
              shadow: "0 15px 50px -12px rgba(228, 58, 25, 0.25);",
            }}
          >
            <Icon as={feature.icon} size={24} color={"rivr.red"} />
            <VStack align={"start"} spacing={1}>
              <Text fontWeight={"semibold"} fontSize={"lg"}>
                {feature.title}
              </Text>
              <Text color={"gray.300"} sx={{ textWrap: "pretty" }}>
                {feature.description}
              </Text>
            </VStack>
          </VStack>
        ))}
      </SimpleGrid>
      <HStack align={"center"} justify={"center"} pt={10}>
        <Button
          rightIcon={<Send />}
          minW="fit-content"
          variant="outline"
          aria-label="Contact"
          as="a"
          href="#contact"
          backdropFilter="blur(2px)"
          onClick={() =>
            handleAmplitudeTrack("Contact", {
              location: "Features",
            })
          }
        >
          Contact
        </Button>
        <Button
          rightIcon={<Calendar />}
          minW="fit-content"
          bg="rivr.red"
          variant="solid"
          _hover={{ bg: "red.300" }}
          _active={{ bg: "red.300" }}
          aria-label="Book a demo"
          as="a"
          href="https://meetings.hubspot.com/aidan-kessell"
          target="_blank"
          onClick={() =>
            handleAmplitudeTrack("Book Demo", {
              location: "Features",
            })
          }
        >
          Book a demo
        </Button>
      </HStack>
    </Box>
  );
};

export default B2BFeatures;
