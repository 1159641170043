import { useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import UncoverTrends from "../../assets/case-trends.png";
import SuperFans from "../../assets/case-superfans.png";
import Feedback from "../../assets/case-feedback.png";
import Safety from "../../assets/case-safety.png";
import Deliverables from "../../assets/case-deliverables.png";
import * as amplitude from "@amplitude/analytics-browser";

const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
  amplitude.track(e, properties);
};

const cases = [
  {
    title: "Uncover trends",
    description:
      "Lean into emerging trends in real-time to stay ahead of the curve.",
    image: UncoverTrends,
  },
  {
    title: "Discover super fans",
    description:
      "Go beyond sponsored creators. Cast a wide net and discover creators and communities of all sizes that champion your brand.",
    image: SuperFans,
  },
  {
    title: "Identify issues, gather feedback and sentiment",
    description:
      "Pinpoint bugs, gather user feedback, and gauge audience sentiment with precision.",
    image: Feedback,
  },
  {
    title: "Check for brand safety",
    description:
      "Monitor creators of interest and ensure your brand is represented safely and appropriately.",
    image: Safety,
  },
  {
    title: "Verify campaign deliverables",
    description:
      "Ensure each creator delivered their talking points, brand callouts, and sponsored disclosures across your campaign.",
    image: Deliverables,
  },
];

const B2BCases = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleAccordionChange = (index: any) => {
    if (index !== null) {
      setSelectedIndex(index);
    }
  };

  return (
    <Box w="100%" px={6} maxW={"1400px"} mx={"auto"} pt={[32, 32, 64]}>
      <VStack w="100%" spacing={6} pb={[8, 8, 16]}>
        <Heading as={"h2"} textAlign={"center"}>
          Limitless potential
        </Heading>
        <Text
          fontSize={"lg"}
          textAlign={"center"}
          color={"gray.300"}
          sx={{ textWrap: "pretty" }}
        >
          Rivr's flexibility makes it the ultimate platform for empowering every
          team in your organization.
        </Text>
      </VStack>
      <HStack
        align="start"
        flexDirection={{ base: "column", md: "row" }}
        w={"100%"}
        gap={[0, 0, 12]}
      >
        <VStack w={{ base: "100%", md: "45%" }} spacing={4}>
          <Accordion
            onChange={(index) => handleAccordionChange(index)}
            w={"full"}
            defaultIndex={0}
            mb={8}
          >
            {cases.map((caseItem, index) => (
              <AccordionItem key={index} borderBottomWidth={"0 !important"}>
                <AccordionButton
                  _expanded={{ color: "rivr.red" }}
                  _hover={{ bg: "transparent" }}
                  onClick={() =>
                    handleAmplitudeTrack("Use Case Clicked", {
                      location: "Cases",
                      title: caseItem.title,
                    })
                  }
                >
                  <Box
                    flex="1"
                    textAlign="left"
                    fontSize={"lg"}
                    fontWeight={"semibold"}
                  >
                    {caseItem.title}
                  </Box>
                  <AccordionIcon boxSize={8} />
                </AccordionButton>
                <AccordionPanel pt={2} pb={6} color={"gray.300"}>
                  {caseItem.description}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </VStack>
        <Box
          w={{ base: "100%", md: "55%" }}
          ml={"0 !important"}
          borderWidth={1}
          borderColor={"rivr.red"}
          borderRadius={"md"}
        >
          {cases[selectedIndex] && (
            <Image
              src={cases[selectedIndex].image}
              alt={cases[selectedIndex].title}
              borderRadius={"md"}
              shadow={"0 15px 50px -12px rgba(228, 58, 25, 0.25)"}
            />
          )}
        </Box>
      </HStack>
    </Box>
  );
};

export default B2BCases;
