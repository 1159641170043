import {
  Box,
  Grid,
  GridItem,
  Divider,
  Heading,
  Text,
  Image,
  Tag,
  VStack,
  HStack,
  Button,
  Flex,
  SlideFade,
  IconButton,
} from "@chakra-ui/react";
import React, { useState, useMemo } from "react";
import Empty from "../assets/roadmap/empty.png";

import MomentRecommondations from "../assets/roadmap/moment-recommendations.png";
import Hype from "../assets/roadmap/hype.png";
import ChatPanel from "../assets/roadmap/chat-panel.png";
import Similarity from "../assets/roadmap/similarity.png";
import Speech from "../assets/roadmap/speech-panel.png";
import ImageSearch from "../assets/roadmap/image-search.png";
import CampaignView from "../assets/roadmap/campaign-view.png";
import UniversalSearch from "../assets/roadmap/universal-search.png";
import Notifications from "../assets/roadmap/notifications.png";
import Access from "../assets/roadmap/access.png";
import Upload from "../assets/roadmap/upload.png";
import CrowdControl from "../assets/roadmap/crowd-control.png";
import Youtube from "../assets/roadmap/youtube.png";
import Share from "../assets/roadmap/share.png";
import Vertical from "../assets/roadmap/vertical.png";
import Creativity from "../assets/roadmap/creativity.png";
import Keys from "../assets/roadmap/keys.png";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const categories = {
  Discovery: [],
  "Pro & Business": [],
  "Integrations & Partnerships": [],
  "Creativity & Polish": [],
};

const categoryDesc = {
  Discovery:
    "Uncover new Moments and better understand your audience with improved search capabilities.",
  "Pro & Business":
    "Access advanced tools to manage, discover, and share quality content and insights at scale.",
  "Creativity & Polish":
    "Take your content from raw to polished with new design and workflow features.",
  "Integrations & Partnerships":
    "Expand your capabilities with seamless integrations and partner services.",
};

const cardData = [
  {
    title: "Moment recommendations",
    category: "Discovery",
    tags: ["Completed"],
    imageUrl: MomentRecommondations,
    description:
      "Automatically generated Moment recommendations based on our point-of-interest algorithm.",
  },
  {
    title: "Hype 2.0",
    category: "Discovery",
    tags: ["Completed"],
    imageUrl: Hype,
    description:
      "Better detect fun and exciting Moments with improvements to our Hype algorithm.",
  },
  {
    title: "Chat search",
    category: "Discovery",
    tags: ["Completed"],
    imageUrl: ChatPanel,
    description:
      "Search your chat history to find anything said by your community.",
  },
  {
    title: "Similarity search",
    category: "Discovery",
    tags: ["Mid-term"],
    imageUrl: Similarity,
    description:
      "Use existing Moments as references to discover similar Moments.",
  },
  {
    title: "Image search",
    category: "Discovery",
    tags: ["Long-term"],
    imageUrl: ImageSearch,
    description:
      "Use visual aspects of your media like logos and UI elements to find other Moments when they appear.",
  },
  {
    title: "Speech search",
    category: "Discovery",
    tags: ["Completed"],
    imageUrl: Speech,
    description:
      "Identify anything said aloud by searching transcribed speech.",
  },
  {
    title: "Reformatting customization",
    category: "Creativity & Polish",
    tags: ["Mid-term"],
    imageUrl: Vertical,
    description:
      "Explore more vertical reformatting options including facecam shapes, bumpers, and watermarks.",
  },
  {
    title: "Creativity suite",
    category: "Creativity & Polish",
    tags: ["Long-term"],
    imageUrl: Creativity,
    description:
      "Add final touches with simple video editing tools, music, and captions, all centralized in the Rivr platform.",
  },
  {
    title: "Crowd Control",
    link: "https://crowdcontrol.live",
    category: "Integrations & Partnerships",
    tags: ["Near-term"],
    imageUrl: CrowdControl,
    description:
      "Import Crowd Control sessions to your Timeline to easily find viewer-triggered Moments.",
  },
  {
    title: "Improved YouTube support",
    category: "Integrations & Partnerships",
    tags: ["Near-term"],
    imageUrl: Youtube,
    description: "Analyze YouTube videos at faster speeds.",
  },
  {
    title: "Direct social exporting",
    category: "Integrations & Partnerships",
    tags: ["Mid-term"],
    imageUrl: Share,
    description:
      "Share your Moments directly to TikTok, YouTube, Instagram, and other platforms.",
  },
  {
    title: "Campaign view",
    category: "Pro & Business",
    tags: ["Completed"],
    imageUrl: CampaignView,
    description:
      "Get a bird’s-eye view of all campaign activity, including analzyed media, Moment recommendations, and user-generated content.",
  },
  {
    title: "Universal search",
    category: "Pro & Business",
    tags: ["Completed"],
    imageUrl: UniversalSearch,
    description:
      "Search all previously analyzed media for chat mentions, spoken callouts, and recommended or user-defined Moments.",
  },
  {
    title: "Direct uploading",
    category: "Pro & Business",
    tags: ["Long-term"],
    imageUrl: Upload,
    description:
      "Upload local or cloud-hosted media files directly to Rivr for analysis.",
  },
  {
    title: "Automatic VOD processing and notifications",
    category: "Pro & Business",
    tags: ["Completed"],
    imageUrl: Notifications,
    description:
      "Analyze VODs as soon as they’re available and get email notifications when they are ready for review.",
  },
  {
    title: "Access controls",
    category: "Pro & Business",
    tags: ["Near-term"],
    imageUrl: Access,
    description:
      "Grant Rivr access and customize permissions for editors, managers, and other team members.",
  },
  {
    title: "Creator keys",
    category: "Pro & Business",
    tags: ["Completed"],
    imageUrl: Keys,
    description:
      "Generate and distribute access keys to Rivr. Track usage, content, sentiment, mentions, and more.",
  },
];

const badgeColors = {
  Completed: "blue",
  "Near-term": "green",
  "Mid-term": "orange",
  "Long-term": "red",
};

const tagPriority = {
  Completed: 1,
  "Near-term": 2,
  "Mid-term": 3,
  "Long-term": 4,
};

const filterButtonColors = {
  Completed: "blue",
  "Near-term": "green",
  "Mid-term": "orange",
  "Long-term": "red",
};

const getHighestPriority = (tags) => {
  return tags.reduce((highest, tag) => {
    const priority = tagPriority[tag] || Infinity;
    return priority < highest ? priority : highest;
  }, Infinity);
};

cardData.forEach((card) => {
  if (categories[card.category]) {
    categories[card.category].push(card);
  }
});

const Card = React.memo(({ title, tags, imageUrl, description, link }) => (
  <Box
    borderWidth={1}
    borderRadius={"lg"}
    overflow={"hidden"}
    h={"100%"}
    p={4}
    sx={{
      bg: "whiteAlpha.100",
      transition: "all .25s",
      ":hover": {
        bg: "whiteAlpha.200",
        transform: "translateY(-1px)",
        borderColor: "rivr.red",
        shadow: "0 15px 50px -12px rgba(228, 58, 25, 0.25);",
      },
    }}
  >
    <Image src={imageUrl} alt={title} borderRadius={"md"} />
    <VStack alignItems={"flex-start"} mt={4}>
      <HStack>
        <Heading size={"md"} fontWeight={"medium"}>
          {title}
        </Heading>
        {link && (
          <IconButton
            as="a"
            href={link}
            target="_blank"
            icon={<ExternalLinkIcon />}
            size="xs"
          />
        )}
      </HStack>
      <Box>
        {tags.map((tag) => (
          <Tag key={tag} mr={2} colorScheme={badgeColors[tag]}>
            {tag}
          </Tag>
        ))}
      </Box>
      <Text mt={2} color={"gray.300"}>
        {description}
      </Text>
    </VStack>
  </Box>
));

const CategorySection = React.memo(({ categoryName, description, cards }) => (
  <>
    <Divider my={12} />
    <Grid
      templateColumns={{ sm: "1fr", md: "1fr 4fr" }}
      gap={10}
      my={10}
      transition={"all .25s"}
    >
      <VStack alignItems={"flex-start"}>
        <Heading size={"md"} fontWeight={"semibold"}>
          {categoryName}
        </Heading>
        <Text fontSize={"md"} color={"gray.300"}>
          {description}
        </Text>
      </VStack>
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        gap={4}
      >
        {cards.map((card, index) => (
          <SlideFade key={index} in={true} reverse={true}>
            <GridItem key={index} h={"100%"}>
              <Card
                title={card.title}
                link={card.link}
                tags={card.tags}
                imageUrl={card.imageUrl}
                description={card.description}
              />
            </GridItem>
          </SlideFade>
        ))}
      </Grid>
    </Grid>
  </>
));

const Roadmap = () => {
  const [selectedTags, setSelectedTags] = useState({
    Completed: true,
    "Near-term": true,
    "Mid-term": true,
    "Long-term": true,
  });

  const handleFilterChange = (tag) => {
    setSelectedTags((prev) => ({ ...prev, [tag]: !prev[tag] }));
  };

  const { sortedCardData, categorizedCardData } = useMemo(() => {
    const sortedData = [...cardData].sort((a, b) => {
      return getHighestPriority(a.tags) - getHighestPriority(b.tags);
    });

    const categorizedData = Object.keys(categories).reduce((acc, category) => {
      acc[category] = sortedData.filter((card) => card.category === category);
      return acc;
    }, {});

    return { sortedCardData: sortedData, categorizedCardData: categorizedData };
  }, []);

  const tagCounts = Object.keys(tagPriority).reduce((acc, tag) => {
    acc[tag] = sortedCardData.filter((card) => card.tags.includes(tag)).length;
    return acc;
  }, {});

  const filteredCategories = Object.keys(categorizedCardData).reduce(
    (acc, category) => {
      acc[category] = categorizedCardData[category].filter((card) =>
        card.tags.some((tag) => selectedTags[tag])
      );
      return acc;
    },
    {}
  );

  const isAnyCardAvailable = Object.values(filteredCategories).some(
    (category) => category.length > 0
  );

  return (
    <Box
      w={"100%"}
      height={"100%"}
      position={"relative"}
      bg={"rivr.black"}
      px={6}
      pb={16}
    >
      <Box mx={"auto"} maxW={"1400px"} pt={[28, 28, 36]}>
        <Flex
          justifyContent={"space-between"}
          alignItems={"flex-end"}
          pb={12}
          wrap={"wrap"}
          gap={6}
        >
          <VStack justifyContent={"flex-start"} alignItems={"flex-start"}>
            <Heading>Roadmap</Heading>
            <Text color={"gray.300"}>
              Upcoming additions to the Rivr experience.
            </Text>
          </VStack>
          <HStack pt={3} flexWrap={"wrap"} rowGap={2}>
            <Text fontSize={"sm"} color={"gray.400"}>
              Show:
            </Text>
            {Object.keys(tagPriority).map((tag) => (
              <Button
                key={tag}
                onClick={() => handleFilterChange(tag)}
                colorScheme={filterButtonColors[tag]}
                variant={selectedTags[tag] ? "solid" : "outline"}
                size={"sm"}
                w={"120px"}
              >
                {tag} ({tagCounts[tag]})
              </Button>
            ))}
          </HStack>
        </Flex>
        {isAnyCardAvailable ? (
          Object.entries(filteredCategories).map(
            ([categoryName, cards]) =>
              cards.length > 0 && (
                <CategorySection
                  key={categoryName}
                  categoryName={categoryName}
                  cards={cards}
                  description={categoryDesc[categoryName]}
                />
              )
          )
        ) : (
          <>
            <Divider my={12} />
            <Box textAlign="center" py={16}>
              <Image src={Empty} mx={"auto"} opacity={"0.1"} maxW={20} pb={3} />
              <Text fontSize="xl" fontWeight="medium">
                No items to display
              </Text>
              <Text color="gray.300">Please check your filter</Text>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Roadmap;
