import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";

const ConfettiOnLoad = () => {
  const [isExploding, setIsExploding] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsExploding(false);
    }, 10000); // Adjust the delay time as needed
  }, []);

  return (
    <Box w="100%">
      <Confetti
        width={window.innerWidth - 24}
        height={window.innerHeight}
        numberOfPieces={420}
        gravity={0.05}
        recycle={false}
        run={isExploding}
      />
    </Box>
  );
};

export default ConfettiOnLoad;
