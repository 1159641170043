import { Box, Button, Heading, HStack, VStack } from "@chakra-ui/react";
import { Calendar, Send } from "@icon-park/react";
import * as amplitude from "@amplitude/analytics-browser";

const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
  amplitude.track(e, properties);
};

const B2BHeading = () => {
  return (
    <Box>
      <VStack
        w="100%"
        maxW="100%"
        px="6"
        pt={[32, 32, 64]}
        pb={[8, 8, 20]}
        h="100%"
        justifyContent="center"
        mx="auto"
        spacing={8}
      >
        <Heading
          as="h1"
          size={["xl", "2xl", "4xl"]}
          color="gray.50"
          textAlign="center"
          zIndex={100}
          sx={{ textWrap: "balance" }}
          lineHeight="1.2 !important"
        >
          <span
            style={{
              color: "#e43a19",
              fontStyle: "italic",
              marginRight: "1.3%",
            }}
          >
            Rivr
          </span>
          lutionizing Influencer Marketing
        </Heading>
        <Heading
          as="h2"
          size={{ xl: "md", lg: "md", md: "md", sm: "sm" }}
          color="gray.300"
          fontWeight="medium"
          mt={8}
          textAlign="center"
          lineHeight="1.5"
          zIndex={100}
          pb={2}
          sx={{ textWrap: "pretty" }}
          maxW="830px"
        >
          Harness the power of video content. Discover the most impactful
          Moments. Understand creators and their audiences at scale.
        </Heading>
        <HStack align="center" justify="center">
          <Button
            rightIcon={<Send />}
            minW="fit-content"
            variant="outline"
            aria-label="Contact"
            as="a"
            href="#contact"
            backdropFilter="blur(2px)"
            onClick={() =>
              handleAmplitudeTrack("Contact", {
                location: "Hero",
              })
            }
          >
            Contact
          </Button>
          <Button
            rightIcon={<Calendar />}
            minW="fit-content"
            bg="rivr.red"
            variant="solid"
            _hover={{ bg: "red.300" }}
            _active={{ bg: "red.300" }}
            aria-label="Book a demo"
            as="a"
            href="https://meetings.hubspot.com/aidan-kessell"
            target="_blank"
            onClick={() =>
              handleAmplitudeTrack("Book Demo", {
                location: "Hero",
              })
            }
          >
            Book a demo
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default B2BHeading;
