import {
  Flex,
  Text as ChakraText,
  VStack,
  Heading as ChakraHeading,
  Box,
} from "@chakra-ui/react";
import BlogQuote from "./BlogQuote";
import BlogImage from "./BlogImage";
import BlogImageQuote from "./BlogImageQuote";
import BlogInlineBlock from "./BlogInlineBlock";
import BlogVideo from "./BlogVideo";

const Heading = (props) => (
  <ChakraHeading
    fontWeight={"semibold"}
    pt={{ lg: 16, base: 8 }}
    pb={{ lg: 4, base: 2 }}
    ml={{ lg: "32rem !important", base: null }}
    mr={{ lg: "8rem !important", base: 6 }}
    {...props}
  />
);

const Text = (props) => (
  <ChakraText
    ml={{ lg: "32rem !important", base: null }}
    mr={{ lg: "8rem !important", base: 6 }}
    lineHeight={8}
    fontSize={"xl"}
    {...props}
  />
);

const BlogContent = ({ contentArray }) => (
  <Flex w={"100%"}>
    <VStack>
      <VStack
        maxW={"100%"}
        alignContent={"flex-start"}
        alignItems={"flex-start"}
        spacing={6}
      >
        {contentArray.map((item, index) => {
          if (item.type === "Text") {
            return <Text key={index}>{item.content}</Text>;
          } else if (item.type === "Heading") {
            return (
              <Heading
                key={index}
                fontSize={{ xl: "3xl", base: "2xl" }}
                sx={{ textWrap: "balance" }}
              >
                {item.content}
              </Heading>
            );
          } else if (item.type === "Quote") {
            return (
              <BlogQuote
                key={index}
                styleType={item.styleType}
                quote={item.content}
                personName={item.personName}
                personTitle={item.personTitle}
              />
            );
          } else if (item.type === "Image") {
            return (
              <BlogImage
                key={index}
                styleType={item.styleType}
                images={item.images}
                caption={item.caption}
              />
            );
          } else if (item.type === "ImageQuote") {
            return (
              <BlogImageQuote
                key={index}
                image={item.image}
                alt={item.alt}
                quote={item.quote}
                personName={item.personName}
                personTitle={item.personTitle}
              />
            );
          } else if (item.type === "InlineBlock") {
            return (
              <BlogInlineBlock
                key={index}
                headline={item.headline}
                icon={item.icon}
                theme={item.theme}
                text={item.text}
                color={item.color}
              />
            );
          } else if (item.type === "Spacer") {
            return (
              <Box
                key={index}
                h={{
                  xl: item.spacingLarge,
                  lg: item.spacingLarge,
                  md: item.spacingSmall,
                  sm: item.spacingSmall,
                }}
                w="100%"
              >
                <Text textAlign={"center"}>{item.spacerText}</Text>
              </Box>
            );
          } else if (item.type === "Video") {
            return (
              <BlogVideo
                key={index}
                platform={item.platform}
                url={item.url}
                caption={item.caption}
              />
            );
          }

          return null;
        })}
      </VStack>
    </VStack>
  </Flex>
);

export default BlogContent;
