import { useEffect, useState } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Fade,
  Image,
  IconButton,
  Progress,
} from "@chakra-ui/react";
import {
  Amazon,
  DE,
  LC,
  Upforce,
  Raredrop,
  NorthBeach,
} from "../../assets/logos";
import ContactForm from "../HomePage/ContactForm";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import * as amplitude from "@amplitude/analytics-browser";

const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
  amplitude.track(e, properties);
};

const testimonials = [
  {
    avatar: Amazon,
    quote:
      "\"Rivr is a lifesaver for our campaigns. It makes it so easy to get exactly what we're looking for. Looking forward to working with them again on future projects. It's a no-brainer.\"",
    author: "Greg Henninger",
    position: "Global Creator Relations Lead, Amazon Games",
  },
  {
    avatar: LC,
    quote:
      '"We incorporate Rivr seamlessly into our campaigns, helping us reduce cost and hours spent on manual content turn-around for our clients. Capturing authentic and credible moments in near real-time has never been easier."',
    author: "George DePree",
    position: "Chief Executive Officer, Leviathan Core",
  },
  {
    avatar: NorthBeach,
    quote:
      '"Rivr was a great partner for our influencer campaign for The Lord of the Rings: Return to Moria. We were able to easily search through content and created a reactions video for our social channels that helped drive even more interest in the game, allowing us to maximize the overall campaign."',
    author: "Kelly Miller",
    position: "Strategic Brand Marketer, North Beach Games",
  },
  {
    avatar: DE,
    quote:
      '"What Rivr accomplished was previously impossible. Instead of multiple teams crawling through 56 co-streams creating clips, we were able to get over 700 clips in real-time. This opened up all-new possibilities for us in both content production and sentiment analysis."',
    author: "Philip Asher",
    position: "Former Head of Marketing, Digital Extremes",
  },
  {
    avatar: Upforce,
    quote:
      '"Our experience with Rivr has been wonderful. It makes our jobs 100 times easier and makes VOD clipping so much more efficient. Rivr has enabled us as a company to work with many more creators to help build their brands across all social platforms thanks to the use of these amazing tools!"',
    author: "Nick Frankich",
    position: "Chief Executive Officer, Upforce Marketing",
  },
  {
    avatar: Raredrop,
    quote:
      '"Working with the team at Rivr allowed us to turn around a volume of content we\'d never have been able to imagine during our events. Their support was instrumental to our social media and production!"',
    author: "Ben Bowman",
    position: "Chief Strategy Officer, RareDrop",
  },
];

const B2BContact = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [progress, setProgress] = useState(0);
  const speed = 10000; // Testimonial change interval in milliseconds
  const interval = 50; // Progress bar update interval in milliseconds

  useEffect(() => {
    const testimonialInterval = setInterval(() => {
      setCurrentTestimonial(
        (prevIndex) => (prevIndex + 1) % testimonials.length
      );
      setProgress(0);
    }, speed);

    return () => {
      clearInterval(testimonialInterval);
    };
  }, []);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + (interval / speed) * 100;
        return newProgress >= 100 ? 0 : newProgress;
      });
    }, interval);

    return () => {
      clearInterval(progressInterval);
    };
  }, [currentTestimonial]);

  const handlePrev = () => {
    setCurrentTestimonial(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
    setProgress(0);
    handleAmplitudeTrack("Previous testimonial");
  };

  const handleNext = () => {
    setCurrentTestimonial((prevIndex) => (prevIndex + 1) % testimonials.length);
    setProgress(0);
    handleAmplitudeTrack("Next testimonial");
  };

  return (
    <Box w={"100%"} px={6} py={[32, 32, 64]}>
      <HStack
        w={"100%"}
        maxW={"1400px"}
        mx={"auto"}
        gap={6}
        flexDirection={{ base: "column", md: "row" }}
        align={"start"}
      >
        <VStack
          w={{ base: "100%", md: "50%" }}
          bg={"whiteAlpha.50"}
          p={6}
          borderRadius={"md"}
          borderWidth={1}
          shadow={"xl"}
          align={"start"}
          backdropFilter={"blur(6px)"}
        >
          <Fade key={currentTestimonial} in={true}>
            <VStack align={"start"}>
              <Image
                src={testimonials[currentTestimonial].avatar}
                maxH={16}
                my={4}
              />
              <Text fontSize={"lg"} sx={{ textWrap: "pretty" }} pb={4}>
                {testimonials[currentTestimonial].quote}
              </Text>
              <VStack align={"start"} spacing={1}>
                <Text fontSize={"sm"} color={"gray.300"}>
                  {testimonials[currentTestimonial].author}
                </Text>
                <Text fontSize={"xs"} color={"gray.400"}>
                  {testimonials[currentTestimonial].position}
                </Text>
              </VStack>
            </VStack>
          </Fade>
          <HStack
            width={"100%"}
            justifyContent={"space-between"}
            pt={4}
            alignItems={"center"}
          >
            <HStack>
              <IconButton
                aria-label="Previous testimonial"
                icon={<ChevronLeftIcon boxSize={4} />}
                borderRadius={"full"}
                variant={"outline"}
                onClick={handlePrev}
                size={"xs"}
              >
                Previous
              </IconButton>
              <IconButton
                aria-label="Next testimonial"
                icon={<ChevronRightIcon boxSize={4} />}
                borderRadius={"full"}
                variant={"outline"}
                onClick={handleNext}
                size={"xs"}
              >
                Next
              </IconButton>
            </HStack>
            <Progress
              value={progress}
              size={"xs"}
              width={"3rem"}
              borderRadius={"full"}
              colorScheme={"gray"}
              mt={4}
              background={"rivr.red"}
              transition={"width 0.1s linear"}
            />
          </HStack>
        </VStack>
        <Box
          w={{ base: "100%", md: "50%" }}
          bg={"whiteAlpha.50"}
          p={6}
          borderRadius={"md"}
          borderWidth={1}
          shadow={"xl"}
          ml={"0 !important"}
          backdropFilter={"blur(6px)"}
        >
          <ContactForm />
        </Box>
      </HStack>
    </Box>
  );
};

export default B2BContact;
