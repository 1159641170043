import { VStack, Divider, Link } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import BlogHeading from "../../../../components/Blog/BlogHeading";
import BlogDetails from "../../../../components/Blog/BlogDetails";
import BlogContent from "../../../../components/Blog/BlogContent";
import BlogCta from "../../../../components/Blog/BlogCta";

import Marc from "../../../../assets/marc.jpg";

import DjHeader from "../../../../assets/blog/spotlights/deejay-knight/dj-header.png";
import DjUi from "../../../../assets/blog/spotlights/deejay-knight/dj-ui.webp";
import DjGroup from "../../../../assets/blog/spotlights/deejay-knight/dj-group.jpg";

const mode = "creatorSpotlight"; // "caseStudy" or "creatorSpotlight" or "partnership"

const data = {
  pageUrl: "https://rivr.stream/blog/creator-spotlight-deejayknight",
  heroImage: DjHeader,
  headlineBusiness: "DeejayKnight",
  headline:
    "How the beloved streamer is creating more feel-good content in less time with Rivr",
  subline:
    "With the ability to quickly discover and share his best Moments while learning what his community loves, DeejayKnight is embracing the joy of content creation.",
  tags: ["Primary Tag", "Secondary Tag", "Another Tag", "asldknalsknd"],
  author: "Marc Singer",
  authorImage: Marc,
  authorLink: "https://x.com/MarcFromRivr",
  date: "Jan 30, 2024",
  contextCopy:
    "We're highlighting creators using Rivr to share better content and make waves on the internet.",
  socials: [
    { name: "Twitch", url: "https://www.twitch.tv/deejayknight" },
    {
      name: "Tiktok",
      url: "https://www.tiktok.com/@deejayknight",
      theme: "filled",
      iconSize: "20",
    },
    {
      name: "YouTube",
      url: "https://www.youtube.com/deejayknight",
      theme: "outline",
      iconSize: "26",
    },
    {
      name: "Twitter",
      url: "https://twitter.com/DeejayKnight",
      theme: "filled",
      iconSize: "20",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/deejayknight/",
      iconSize: "22",
    },
  ],
  shareTitle: "Rivr - Creator Spotlight: Deejay Knight",
  shareDesc:
    "How the beloved streamer is creating more feel-good content in less time with Rivr",
  shareImg: DjHeader,
  type: "article",
};

const details = {
  businessType: "Test Business Type",
  locationCity: "City",
  locationCountry: "Country",
  tech: ["Technology", "Technology", "Technology"],
  ctaText: "CTA Text Here",
};

const content = [
  {
    type: "Spacer",
    spacingSmall: 10,
    spacingLarge: 10,
  },
  {
    type: "Text",
    content: (
      <>
        DeejayKnight is a space and sci-fi gamer, streamer, voice actor, and{" "}
        <Link
          href="https://twitter.com/TeamLiquid/status/1678800741090353154"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          Team Liquid
        </Link>{" "}
        creator with a loyal audience numbering 127K strong. A veteran of the
        U.S. Air Force and educator with a background in interface and game
        design, he kicked off his{" "}
        <Link
          href="https://www.twitch.tv/deejayknight"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          Twitch
        </Link>{" "}
        broadcasting career over a decade ago and never looked back.
      </>
    ),
  },
  {
    type: "Video",
    platform: "Twitch",
    url: "https://player.twitch.tv/?video=1432855312",
    caption:
      "DeejayKnight was selected for The Game Awards’ 2021 Future Class as a creator representing the inclusive future of video games.",
  },
  {
    type: "Text",
    content: (
      <>
        Today, DeejayKnight reliably serves up laughs and laid-back positivity
        for his community, the Knight Corps, who he describes as “snarky, fun,
        intelligent, and always down to clown.” Whether it’s sarcastic banter
        with the chat or singing his way through sci-fi titles like{" "}
        <em>Star Citizen</em> or <em>Cyberpunk 2077</em> – often with some wild
        vocal effects thrown in – the good times come easily.
      </>
    ),
  },
  {
    type: "Quote",
    styleType: "small",
    content:
      "What gets me excited about streaming is helping people learn and making their days better with comedy and good vibes.",
  },
  {
    type: "Text",
    content:
      "But finding highlights in his streams and sharing them with the world? That’s tougher – and time-consuming – so he uses Rivr to show him where the hype is and gain key insights about what gets his fans excited.",
  },
  {
    type: "Spacer",
    spacingSmall: 2,
    spacingLarge: 10,
  },
  {
    type: "InlineBlock",
    headline: "DeejayKnight's creator tip",
    icon: "Tips",
    theme: "outline",
    color: "#F6E05E",
    text: (
      <>
        "If you're a creator looking to build your streaming career, the most
        important thing I could tell you is to get started! The lessons to be
        learned and the moments to be excited about truly come from being in the
        moment and experiencing them. The more you practice the craft, the
        better you get at it."
      </>
    ),
  },
  { type: "Heading", content: "Beating the content creation time crunch ⏳" },
  {
    type: "Text",
    content:
      "Time waits for no one, but audiences do wait for content from their faves. And, like a lot of pro creators, DeejayKnight was looking for a way to streamline his workflow and produce better content from his VODs at a faster pace, without having to spend hours scrubbing through them.",
  },
  {
    type: "Quote",
    styleType: "large",
    content:
      "My major challenges revolved around finding the content I was looking for and getting that content downloaded in decent enough quality to do anything with.",
  },
  {
    type: "Text",
    content: (
      <>
        In the past, he would sometimes rely on viewers to grab clips of his
        streams for him. Otherwise, he’d be stuck hunting for the good stuff
        himself, which often felt like a drag after wrapping up a lengthy
        streaming session. He also tried out a few AI autoclippers to help
        reduce the workload of identifying highlights, but didn’t like how they
        were limited to gameplay footage and only supported certain titles.
      </>
    ),
  },
  {
    type: "Text",
    content:
      "What DeejayKnight really wanted was a solution that would work for all kinds of content, not just gameplay. He also wanted full control over the output without sacrificing video quality.",
  },
  {
    type: "Text",
    content: (
      <>
        Luckily, fellow streamer{" "}
        <Link
          href={"https://www.twitch.tv/smashley"}
          target={"_blank"}
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          Smashley
        </Link>{" "}
        suggested he check out Rivr. Once he got started, he quickly became one
        of our biggest beta-tester champions.
      </>
    ),
  },
  {
    type: "Quote",
    styleType: "large",
    content:
      "Rivr saves me huge amounts of time because I don’t have to scrub through 4-to-8-hour VODs looking for the most interesting clips.",
  },
  {
    type: "Text",
    content:
      "Now, Rivr plays a key role in DeejayKnight’s workflow. With Signal data to guide him, Rivr helps DeejayKnight quickly find the best Moments in his VODs, trim them down, and batch export them for use across his social channels. The process is a huge time-saver, and he’s also discovering more and more about what his audience responds to.",
  },
  {
    type: "Spacer",
    spacingSmall: 6,
    spacingLarge: 14,
  },
  {
    type: "Image",
    styleType: "single",
    images: [DjGroup],
    caption:
      "DeejayKnight at TwitchCon 2023 with Andy Lunique, Malika Lim Eubank of HyperRPG, MyLawyerFriend, and Chris Eddie of AlchemyRPG.",
  },
  { type: "Heading", content: "Making the most of audience insights 📈" },
  {
    type: "Text",
    content:
      "It isn’t always obvious what gets an audience fired up, and great Moments can sometimes slip by unnoticed in the midst of a streaming session. That’s why DeejayKnight loves having access to multiple kinds of Signal data: Chat Activity, Audio Intensity, Hype, and especially Chat Response.",
  },
  {
    type: "Text",
    content:
      "By searching his VODs in Rivr and filtering by Chat Response, he’s able to easily pinpoint specific sections where his community reacted enthusiastically – whether it was to in-game action, a goofy pun, or him belting out an old-school jam. Those Moments are the ones most likely to end up on social media, and he uses what he learns about his audience’s preferences to tailor his future streams and content strategy.",
  },
  {
    type: "ImageQuote",
    image: DjUi,
    alt: "DeejayKnight Hype Moments in Rivr",
    quote:
      "Rivr shows me Moments where my community is excited about something I wouldn’t have expected, which makes it easier for me to take those and try to incorporate them into my broadcasts more often.",
  },
  {
    type: "Text",
    content:
      "This workflow not only speeds things up, but also drives organic growth. The more DeejayKnight uses Rivr, the better he’s able to understand and leverage exactly what keeps his fans coming back. In this way, he’s constantly reinforcing his unique brand and expanding his reach.",
  },
  { type: "Heading", content: "Learning to love content creation ❤️‍🔥" },
  {
    type: "Text",
    content:
      "Rivr has been a game-changing tool for DeejayKnight, simplifying content discovery, improving workflow efficiency, and enabling him to engage his community more effectively. The ability to quickly identify and share the most exciting Moments in his streams has freed up hours in his schedule and sparked newfound enthusiasm for the content creation process.",
  },
  {
    type: "Quote",
    styleType: "small",
    content:
      "I find myself spending more time creating social content now, whereas previously, I spent none. Rivr has made it exciting to find new content to post online.",
  },
  {
    type: "Text",
    content:
      "With Rivr, DeejayKnight has turned what was once a daunting task into a process he looks forward to. The Knight Corps is benefiting, too, by getting more frequent, high-quality content to engage with across multiple platforms. They may not know it, but Rivr is helping their favorite creator hone in on what they enjoy so that he can keep delivering cheerful, uplifting content that resonates.",
  },
  {
    type: "Text",
    content:
      "For any creator looking to enhance their own content creation journey, DeejayKnight’s message is clear:",
  },
  {
    type: "Quote",
    styleType: "small",
    content: (
      <>
        Rivr is amazing. If you’re thinking about using it,{" "}
        <Link
          href={"/beta-request?r=DeejayKnight"}
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          start right now!
        </Link>
      </>
    ),
  },
  {
    type: "InlineBlock",
    headline: "Follow DeejayKnight",
    icon: "Journey",
    theme: "outline",
    color: "#63B3ED",
    text: (
      <>
        If you're looking to have some fun singing under the stars and blasting
        away at baddies, check him out on{" "}
        <Link
          href="https://www.twitch.tv/deejayknight"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          Twitch
        </Link>
        .
      </>
    ),
  },
];

const cta = {
  styleType: "spotlight",
  headline: "Better content at light speed",
  subline:
    "Tell your story, support creators, and boost engagement for campaigns large or small with Rivr’s enterprise tools.",
  buttonText: "Learn more",
  buttonLink: "/brands",
};

const SpotlightDeejayKnight = () => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{data.shareTitle}</title>
        <meta name="title" content={data.shareTitle} />
        <meta name="description" content={data.shareDesc} />
        <meta property="og:title" content={data.shareTitle} />
        <meta property="og:description" content={data.shareDesc} />
        <meta property="og:image" content={data.shareImg} />
        <meta property="og:url" content={data.pageUrl} />
        <meta property="og:type" content={data.type} />
        <meta name="twitter:title" content={data.shareTitle} />
        <meta name="twitter:description" content={data.shareDesc} />
        <meta name="twitter:image" content={data.shareImg} />
        <meta name="twitter:url" content={data.pageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@rivrstream" />
      </Helmet>
      <VStack justifyContent={"center"} bg="rivr.black">
        <VStack maxW={"1400px"} mx={6} pt={16} pb={24}>
          <BlogHeading
            mode={mode}
            pageUrl={data.pageUrl}
            headlineBusiness={data.headlineBusiness}
            headline={data.headline}
            heroImage={data.heroImage}
            subline={data.subline}
            author={data.author}
            authorImage={data.authorImage}
            authorLink={data.authorLink}
            contextCopy={data.contextCopy}
            date={data.date}
            tags={data.tags}
            socials={data.socials}
            shareTitle={data.shareTitle}
          />
          {mode === "creatorSpotlight" || mode === "partnership" ? (
            <Divider bg={"gray.400"} mb={2} />
          ) : null}
          {mode === "caseStudy" ? (
            <BlogDetails
              businessType={details.businessType}
              locationCity={details.locationCity}
              locationCountry={details.locationCountry}
              tech={details.tech}
              ctaText={details.ctaText}
            />
          ) : null}
          <BlogContent contentArray={content} />
        </VStack>
        <BlogCta
          styleType={cta.styleType}
          headline={cta.headline}
          subline={cta.subline}
          buttonLink={cta.buttonLink}
          buttonText={cta.buttonText}
        />
      </VStack>
    </>
  );
};

export default SpotlightDeejayKnight;
