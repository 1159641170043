import { useState, useEffect } from "react";
import { Box, Heading } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";

const TextRotator = ({ messages, interval }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentMessageIndex((currentMessageIndex + 1) % messages.length);
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [currentMessageIndex, messages, interval]);

  return (
    <Box>
      <AnimatePresence mode="wait">
        <motion.div
          key={currentMessageIndex}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 40 }}
          transition={{ duration: 0.5, ease: "easeInOut", type: "tween" }}
        >
          <Heading
            as="h2"
            fontSize={["2xl", null, "3xl"]}
            color={"rivr.red"}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            {messages[currentMessageIndex]}
          </Heading>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default TextRotator;
