import { HStack, VStack, Text, Flex, Icon } from "@chakra-ui/react";
import {
  Lightning,
  Tips,
  Topic,
  Journey,
  Increase,
  Level,
  Optimize,
} from "@icon-park/react";

const iconMapping = {
  Lightning: Lightning,
  Tips: Tips,
  Topic: Topic,
  Journey: Journey,
  Increase: Increase,
  Level: Level,
  Optimize: Optimize,
};

const BlogInlineBlock = ({ headline, icon, theme, text, color }) => {
  const IconComponent = iconMapping[icon];
  return (
    <Flex
      ml={{ lg: "32rem !important", base: null }}
      mr={{ lg: "8rem !important", base: 6 }}
      py={{ lg: 6, base: 3 }}
    >
      <VStack
        bg="whiteAlpha.200"
        borderWidth={1}
        transition={"all 0.2s"}
        _hover={{ borderColor: "rivr.red" }}
        w="100%"
        p={{ lg: 20, base: 12 }}
        borderRadius={"3xl"}
        alignItems={"stretch"}
        spacing={6}
      >
        <HStack justifyContent={"space-between"}>
          <Text fontSize={{ lg: "4xl", base: "2xl" }} fontWeight={"semibold"}>
            {headline}
          </Text>
          <Icon
            pt={3}
            as={IconComponent}
            theme={theme}
            strokeWidth={0}
            boxSize="32"
            size="32"
            color={color}
          />
        </HStack>
        <Text fontSize={{ lg: "xl", base: "lg" }} color="gray.300">
          {text}
        </Text>
      </VStack>
    </Flex>
  );
};

export default BlogInlineBlock;
