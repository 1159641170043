import { useState } from "react";
import axios from "axios";
import {
  Button,
  Input,
  Text,
  Image,
  VStack,
  useToast,
  FormLabel,
  FormControl,
  Link,
  Box,
} from "@chakra-ui/react";
import Logos from "../assets/logos.png";
import AGSBG from "../assets/ags-bg.png";
import { Send, Trophy } from "@icon-park/react";
import Confetti from "./ConfettiOnLoad";

const AmazonEmailCapture = () => {
  const [email, setEmail] = useState("");
  const [channel, setChannel] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [channelError, setChannelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const toast = useToast();

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateChannel = (channel) => {
    const pattern = /^(https?:\/\/)?(www\.)?twitch\.tv\/.+$/;
    return pattern.test(channel);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateEmail(email)) {
      setEmailError(true);
      toast({
        title: "Invalid Email",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 7000,
        isClosable: false,
      });
      setIsLoading(false);
      return;
    } else {
      setEmailError(false);
    }

    if (!validateChannel(channel)) {
      setChannelError(true);
      toast({
        title: "Invalid Twitch Channel",
        description: "Please enter a valid Twitch channel URL.",
        status: "error",
        duration: 7000,
        isClosable: false,
      });
      setIsLoading(false);
      return;
    } else {
      setChannelError(false);
    }

    const data = new FormData();
    data.append("entry.465265828", email);
    data.append("entry.665656488", channel);

    try {
      await axios.post(
        "https://docs.google.com/forms/u/1/d/e/1FAIpQLSe-vZ05H76zeVgpdfustOmB82oGSLho9B8koPMr0AvZKK0zJA/formResponse",
        data
      );
      toast({
        title: "Submission Successful",
        description: "You will hear from us soon.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setEmail("");
      setChannel("");
      setIsSubmitted(true);
    } catch (err) {
      if (err.code === "ERR_NETWORK") {
        toast({
          title: "Submission Successful",
          description: "You will hear from us soon.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setEmail("");
        setChannel("");
        setIsSubmitted(true);
      } else {
        console.error(err);
        toast({
          title: "Submission Failed",
          description: "Please try again later.",
          status: "error",
          duration: 7000,
          isClosable: false,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VStack
      spacing={6}
      justifyContent={"center"}
      alignContent={"center"}
      alignItems={"center"}
      width={"100%"}
      height={"100dvh"}
      px={6}
      marginInline={"auto"}
      bgImage={AGSBG}
      bgPos={"bottom"}
      bgRepeat={"no-repeat"}
      bgSize={"cover"}
    >
      {isSubmitted ? (
        <>
          <Confetti />
          <VStack spacing={4} align={"center"}>
            <Text
              fontSize={"2xl"}
              fontWeight={"semibold"}
              role="status"
              id="successMessage"
              tabIndex="-1"
            >
              Thanks for confirming your participation!
            </Text>
            <Text
              textAlign={"center"}
              maxW={"640px"}
              sx={{ textWrap: "balance" }}
            >
              Claim your free one-month{" "}
              <Link
                href={"https://streamersquare.com"}
                target={"_blank"}
                color={"blue.300"}
                textDecor={"underline"}
                _hover={{ color: "blue.400" }}
                role={"link"}
                aria-label={"Visit StreamerSquare for more information"}
              >
                StreamerSquare
              </Link>{" "}
              Premium membership to unlock workshops, courses, and product
              discounts.
            </Text>
            <Box py={3}>
              <Button
                colorScheme={"green"}
                size={"lg"}
                leftIcon={<Trophy />}
                as={"a"}
                href={
                  "https://streamersquare.com/amazon-games-creator-perk-redemption/"
                }
                role={"button"}
                aria-label={
                  "Claim Free 1-month StreamerSquare Premium Membership"
                }
              >
                Claim Premium Membership
              </Button>
            </Box>
            <Text
              fontSize={"xs"}
              color={"gray.400"}
              textAlign={"center"}
              maxW={"640px"}
              sx={{ textWrap: "balance" }}
            >
              If you don’t want to claim your free premium membership right now,
              don’t worry, you’ll be able to later in a follow up email.
            </Text>
          </VStack>
        </>
      ) : (
        <>
          <Image
            src={Logos}
            w={"100%"}
            maxW={"48rem"}
            px={6}
            alt={"Rivr, StreamerSquare, and Amazon Games Creator Program Logos"}
            role={"img"}
          />
          <Text
            textAlign={"center"}
            maxW={"640px"}
            sx={{ textWrap: "balance" }}
          >
            Welcome to an exclusive opportunity in partnership with Amazon
            Games.{" "}
            <Link
              href={"https://rivr.stream"}
              target={"_blank"}
              color={"blue.300"}
              textDecor={"underline"}
              _hover={{ color: "blue.400" }}
              aria-label="Visit Rivr for more information"
              role={"link"}
            >
              Rivr
            </Link>{" "}
            and{" "}
            <Link
              href={"https://streamersquare.com"}
              target="_blank"
              color={"blue.300"}
              textDecor={"underline"}
              _hover={{ color: "blue.400" }}
              aria-label={"Visit StreamerSquare for more information"}
              role={"link"}
            >
              StreamerSquare's
            </Link>{" "}
            goal is to provide you with the tools and education you need to
            succeed as a creator.
          </Text>
          <Text
            textAlign={"center"}
            maxW={"640px"}
            sx={{ textWrap: "balance" }}
          >
            To confirm your participation please fill out the following:
          </Text>
          <VStack w={"100%"}>
            <VStack
              w={"100%"}
              spacing={4}
              bg={"gray.700"}
              p={6}
              borderRadius={"md"}
              borderWidth={1}
              maxW={"400px"}
            >
              <FormControl isInvalid={emailError} id={"email"}>
                <FormLabel htmlFor={"emailInput"}>Confirm your email</FormLabel>
                <Input
                  id={"emailInput"}
                  aria-label={"Enter your email address"}
                  placeholder={"rivr@rivr.stream"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  w={"full"}
                  isRequired
                />
              </FormControl>
              <FormControl isInvalid={channelError} id={"channel"}>
                <FormLabel htmlFor={"channelInput"}>
                  Confirm your Twitch channel
                </FormLabel>
                <Input
                  id={"channelInput"}
                  aria-label={"Enter your Twitch channel URL"}
                  placeholder={"https://twitch.tv/yourchannel"}
                  value={channel}
                  onChange={(e) => setChannel(e.target.value)}
                  w={"full"}
                  isRequired
                />
              </FormControl>
              <Button
                w={"full"}
                colorScheme={"green"}
                mr={3}
                size={"lg"}
                onClick={handleSubmit}
                isLoading={isLoading}
                loadingText={"Submitting..."}
                rightIcon={<Send />}
                aria-label={"Confirm your participation and submit the form"}
                role={"button"}
              >
                Submit
              </Button>
            </VStack>
            <Text fontSize={"xs"} color={"gray.400"}>
              Experiencing an issue with this form?{" "}
              <Link
                href={"mailto:support@rivr.stream"}
                textDecor={"underline"}
                color={"blue.300"}
                _hover={{ color: "blue.400" }}
                role={"link"}
                aria-label={"Link to email Rivr for support"}
              >
                Contact us
              </Link>
              .
            </Text>
          </VStack>
        </>
      )}
      <Text fontSize={"xs"} color={"gray.400"}>
        <Link
          target={"_blank"}
          href={"https://rivr.stream/privacy-terms-of-service"}
          aria-label={"Visit Rivr privacy policy"}
        >
          Rivr Privacy Policy
        </Link>{" "}
        |{" "}
        <Link
          target={"_blank"}
          href={"https://streamersquare.com/privacy-policy/"}
          aria-label={"Visit StreamerSquare privacy policy"}
        >
          StreamerSquare Privacy Policy
        </Link>{" "}
        |{" "}
        <Link
          target={"_blank"}
          href={
            "https://www.amazon.com/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ"
          }
          aria-label={"Visit Amazon privacy policy"}
        >
          Amazon Privacy Policy
        </Link>
      </Text>
    </VStack>
  );
};

export default AmazonEmailCapture;
