import { VStack, Divider, Link } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import BlogHeading from "../../../../components/Blog/BlogHeading";
import BlogDetails from "../../../../components/Blog/BlogDetails";
import BlogContent from "../../../../components/Blog/BlogContent";

import Marc from "../../../../assets/marc.jpg";

import LowcoHeader from "../../../../assets/blog/news/lowco-announce/lowco-header.png";

const mode = "partnership"; // "caseStudy" or "creatorSpotlight" or "partnership"

const data = {
  pageUrl: "https://rivr.stream/blog/rivr-news-lowco-head-of-product",
  heroImage: LowcoHeader,
  //headlineBusiness: "DeejayKnight",
  headline: "Botni.Vision welcomes new Head of Product, Kacey “Lowco” Shields",
  subline:
    "Shields, a well-known content creator, educator, and streaming industry expert, joins the Montreal-based tech startup to lead product strategy and feature development for Rivr, the company’s in-media search platform currently in a closed beta phase.",
  //tags: ["Primary Tag", "Secondary Tag", "Another Tag", "asldknalsknd"],
  author: "Marc Singer",
  authorImage: Marc,
  authorLink: "https://x.com/MarcFromRivr",
  date: "Mar 13, 2024",
  //contextCopy:
  //"We're highlighting creators using Rivr to share better content and make waves on the internet.",
  /*   socials: [
    { name: "Twitch", url: "https://www.twitch.tv/deejayknight" },
    {
      name: "Tiktok",
      url: "https://www.tiktok.com/@deejayknight",
      theme: "filled",
      iconSize: "20",
    },
    {
      name: "YouTube",
      url: "https://www.youtube.com/deejayknight",
      theme: "outline",
      iconSize: "26",
    },
    {
      name: "Twitter",
      url: "https://twitter.com/DeejayKnight",
      theme: "filled",
      iconSize: "20",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/deejayknight/",
      iconSize: "22",
    },
  ], */
  shareTitle:
    "Rivr - News: Botni.Vision welcomes new Head of Product Kacey “Lowco” Shields",
  shareDesc: "Botni.Vision welcomes new Head of Product Kacey “Lowco” Shields",
  shareImg: LowcoHeader,
  type: "article",
};

const details = {
  businessType: "Test Business Type",
  locationCity: "City",
  locationCountry: "Country",
  tech: ["Technology", "Technology", "Technology"],
  ctaText: "CTA Text Here",
};

const content = [
  {
    type: "Spacer",
    spacingSmall: 10,
    spacingLarge: 10,
  },
  {
    type: "Text",
    content: (
      <>
        <Link
          href="https://botni.vision"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          Botni.Vision
        </Link>{" "}
        proudly announces the appointment of Kacey “Lowco” Shields as their new
        head of product for{" "}
        <Link
          href="https://rivr.stream"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          Rivr
        </Link>
        , an in-media search platform enabling digital creators, livestreamers,
        and brands to identify and share the best moments in their videos.
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        Shields is the founder and CEO of{" "}
        <Link
          href="https://streamersquare.com/"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          StreamerSquare
        </Link>
        , an educational and community-building platform for up-and-coming
        content creators. She is also a renowned creator, industry influencer,
        and consultant who brings a wealth of experience and a fresh perspective
        to the Rivr team.
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        “We’re thrilled to have Kacey on board," said Brandon Weinman-Tyre, CEO
        of Botni.Vision. “She’s a creator’s creator who understands the
        streaming space and helps people build careers within it, which makes
        her the perfect person to lead Rivr’s product strategy. We’re all
        looking forward to collaborating with her on tools that enable our users
        to find more value in their content.”
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        With a proven track record of building engaged communities and
        delivering exceptional content, Shields is well positioned to drive
        innovation and elevate the Rivr user experience. As head of product, she
        will spearhead the development of new features and solutions that meet
        the evolving needs of Rivr’s consumer and enterprise users.
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        “I am incredibly excited to join the talented team at Botni.Vision and
        contribute to the next phase of Rivr’s growth," said Shields. “It has
        always been my mission to connect creators with the tools they need to
        succeed, and as one of Rivr’s earliest beta users, I immediately
        recognized the platform’s potential to solve problems that have long
        plagued the livestreaming space. I can’t wait to get it into more
        creators’ hands.”
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        Shields’ unique blend of creativity, strategic vision, and industry
        expertise will be instrumental in shaping the future direction of the
        product. Her appointment comes at a pivotal time for Botni.Vision as the
        company prepares for the end of Rivr’s closed beta period and a
        subsequent wider release.
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        For press inquiries or further information, please email{" "}
        <Link
          href="mailto:press@botni.vision"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          press@botni.vision
        </Link>
        .
      </>
    ),
  },
  {
    type: "Spacer",
    spacingSmall: 10,
    spacingLarge: 10,
  },
  {
    type: "Spacer",
    spacerText: "###",
    spacingSmall: 20,
    spacingLarge: 20,
  },
  {
    type: "Text",
    content: (
      <>
        <b>About Botni.Vision</b>
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        Botni.Vision is a technology company enabling human and artificial
        learning. We make Rivr, a revolutionary in-media search and content
        discovery platform trusted by creators and brands of all sizes. Rivr
        sees inside video and audio experiences, turning media into meaning and
        finding anything seen, heard, said, or felt. With the power to analyze
        at scale in real-time, identify the best Moments, and reformat output
        for any use case, Rivr transforms production workflows and makes it easy
        to create and share authentic content your audience will love. Visit{" "}
        <Link
          href="https://rivr.stream"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          rivr.stream
        </Link>{" "}
        for more information.
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        <b>About Kacey “Lowco” Shields</b>
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        With nearly a decade of experience in the streaming industry, Kacey
        “Lowco” Shields has established herself as a prominent and highly
        respected figure in the creator community. As the founder of
        StreamerSquare, her mission is to empower content creators by providing
        them with essential resources and guidance to elevate their streaming
        endeavors. Often referred to as the “CEO of Streaming,” Lowco
        demonstrates an exceptional ability to track and analyze emerging trends
        across leading platforms, such as Twitch, YouTube, and TikTok. Her
        expertise in the field enables her to offer invaluable advice to
        creators, ensuring that they implement best practices to achieve success
        in an ever-evolving digital landscape.
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        <b>Contact</b>
      </>
    ),
  },
  {
    type: "Text",
    content: (
      <>
        Botni.Vision, Inc.
        <br />
        <Link
          href="mailto:press@botni.vision"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          press@botni.vision
        </Link>
        <br />
        <Link
          href="https://botni.vision"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          botni.vision
        </Link>
        <br />
        <Link
          href="https://rivr.stream"
          target="_blank"
          textDecoration={"underline"}
          _hover={{ color: "green.300" }}
        >
          rivr.stream
        </Link>
      </>
    ),
  },
];

const NewsLowcoAnnounce = () => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{data.shareTitle}</title>
        <meta name="title" content={data.shareTitle} />
        <meta name="description" content={data.shareDesc} />
        <meta property="og:title" content={data.shareTitle} />
        <meta property="og:description" content={data.shareDesc} />
        <meta property="og:image" content={data.shareImg} />
        <meta property="og:url" content={data.pageUrl} />
        <meta property="og:type" content={data.type} />
        <meta name="twitter:title" content={data.shareTitle} />
        <meta name="twitter:description" content={data.shareDesc} />
        <meta name="twitter:image" content={data.shareImg} />
        <meta name="twitter:url" content={data.pageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@rivrstream" />
      </Helmet>
      <VStack justifyContent={"center"} bg="rivr.black">
        <VStack maxW={"1400px"} mx={6} pt={16} pb={24}>
          <BlogHeading
            mode={mode}
            pageUrl={data.pageUrl}
            headlineBusiness={data.headlineBusiness}
            headline={data.headline}
            heroImage={data.heroImage}
            subline={data.subline}
            author={data.author}
            authorImage={data.authorImage}
            authorLink={data.authorLink}
            contextCopy={data.contextCopy}
            date={data.date}
            tags={data.tags}
            socials={data.socials}
            shareTitle={data.shareTitle}
          />
          {mode === "creatorSpotlight" || mode === "partnership" ? (
            <Divider bg={"gray.400"} mb={2} />
          ) : null}
          {mode === "caseStudy" ? (
            <BlogDetails
              businessType={details.businessType}
              locationCity={details.locationCity}
              locationCountry={details.locationCountry}
              tech={details.tech}
              ctaText={details.ctaText}
            />
          ) : null}
          <BlogContent contentArray={content} />
        </VStack>
      </VStack>
    </>
  );
};

export default NewsLowcoAnnounce;
