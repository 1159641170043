import { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";

const BlogVideo = ({ platform, url, caption }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Set the initial state

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (platform === "Youtube") {
    return (
      <>
        <Box pb={3} />
        <Box w="100%" h="100%" position="relative">
          <Box pt={"56%"} h="0">
            <iframe
              allowFullScreen={true}
              src={url}
              title="YouTube video player"
              width="100%"
              height="100%"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </Box>
        </Box>
        <Box pb={8} alignSelf={"center"} w={"100%"}>
          <Text color="gray.400" textAlign={"center"}>
            {caption}
          </Text>
        </Box>
      </>
    );
  }

  if (platform === "Twitch") {
    return (
      <>
        <Box pb={3} />
        <Box
          w={{ xl: "75%", lg: "75%", md: "100%", sm: "100%" }}
          position={"relative"}
          alignSelf={"center"}
        >
          <Box
            pt={"56.25%"}
            position="relative"
            overflow="hidden"
            w={isMobile && "90vw"}
          >
            <iframe
              src={`${url}&parent=${window.location.hostname}&autoplay=false&muted=true`}
              allowFullScreen={true}
              title="Twitch video player"
              style={{
                border: 0,
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
              }}
            />
          </Box>
        </Box>
        <Box
          pb={8}
          alignSelf={"center"}
          w={{ xl: "75%", lg: "75%", md: "100%", sm: "100%" }}
        >
          <Text color="gray.400" textAlign="center">
            {caption}
          </Text>
        </Box>
      </>
    );
  }

  return null;
};

export default BlogVideo;
