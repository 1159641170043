import {
  Flex,
  Heading,
  Text,
  Image,
  IconButton,
  Tag,
  VStack,
  HStack,
  Link,
} from "@chakra-ui/react";
import {
  Facebook,
  Twitter,
  LinkOne,
  Tiktok,
  Youtube,
  Instagram,
} from "@icon-park/react";
import Twitch from "../../assets/twitch-logo.svg";

const iconMapping = {
  Facebook: Facebook,
  Twitter: Twitter,
  Tiktok: Tiktok,
  Website: LinkOne,
  Twitch: (
    <Image
      w={"1.1rem"}
      src={Twitch}
      css={{
        filter: "saturate(0)",
      }}
    />
  ),
  YouTube: Youtube,
  Instagram: Instagram,
};

const BlogHeading = ({
  mode,
  headline,
  headlineBusiness,
  heroImage,
  subline,
  author,
  authorImage,
  authorLink,
  date,
  tags,
  socials,
  contextCopy,
}) => {
  return (
    <Flex w={"100%"} pt={{ lg: 24, base: 8 }}>
      <VStack alignItems={"flex-start"}>
        <VStack spacing={0} alignItems={"flex-start"}>
          {mode === "partnership" ? null : (
            <Heading
              as="h1"
              fontSize={{ lg: "8xl", base: "5xl" }}
              fontWeight={"bold"}
              textAlign={"left"}
              color={"rivr.red"}
            >
              {headlineBusiness}
            </Heading>
          )}
          {mode === "partnership" ? null : (
            <Heading
              as="h1"
              fontSize={{ lg: "6xl", base: "4xl" }}
              fontWeight={"semibold"}
              pb={{ lg: 16, base: 8 }}
              textAlign={mode === "partnership" ? "center" : "left"}
              sx={{ textWrap: "balance" }}
            >
              {headline}
            </Heading>
          )}
        </VStack>
        <HStack
          flexWrap={"wrap"}
          w={"100%"}
          spacing={0}
          justifyContent={mode === "partnership" ? "center" : "space-between"}
          alignItems={"flex-start"}
        >
          <Image
            w={"100%"}
            maxW={mode === "partnership" ? "100%" : "670px"}
            borderRadius={"3xl"}
            borderWidth={2}
            borderColor={"rivr.red"}
            borderStyle={"solid"}
            mb={{ lg: 24, base: 8 }}
            src={heroImage}
          />
          <VStack
            w={"100%"}
            maxW={mode === "partnership" ? "100%" : "670px"}
            h={"100%"}
            pl={{ lg: 16, base: null }}
            alignItems={mode === "partnership" ? "center" : "flex-start"}
            justifyContent={"flex-start"}
            pb={{ lg: 24, base: 8 }}
          >
            <VStack
              alignItems={mode === "partnership" ? "center" : "flex-start"}
            >
              {mode === "partnership" && (
                <Heading
                  as="h1"
                  fontSize={{ lg: "6xl", base: "4xl" }}
                  fontWeight={"semibold"}
                  pb={4}
                  textAlign={"center"}
                  sx={{ textWrap: "balance" }}
                  color={"rivr.red"}
                >
                  {headline}
                </Heading>
              )}
              <Text
                fontSize={"2xl"}
                pb={mode === "partnership" ? null : 6}
                sx={{ textWrap: "balance" }}
                textAlign={mode === "partnership" ? "center" : "left"}
              >
                {subline}
              </Text>
              {mode === "creatorSpotlight" ? (
                <Text
                  textTransform={"uppercase"}
                  letterSpacing={1}
                  fontWeight={"medium"}
                  fontSize={"sm"}
                  color={"rivr.red"}
                >
                  Find Them On
                </Text>
              ) : null}
            </VStack>
            {mode === "caseStudy" || mode === "partnership" ? (
              <HStack
                textTransform={"uppercase"}
                pb={mode === "partnership" ? 6 : 20}
                flexWrap={"wrap"}
              >
                {tags && tags.length > 0
                  ? tags.map((tag, index) => (
                      <Tag
                        key={index}
                        px={2}
                        py={1.5}
                        size={"sm"}
                        colorScheme={"green"}
                        letterSpacing={1}
                        variant={index === 0 ? "solid" : "subtle"}
                      >
                        {tag}
                      </Tag>
                    ))
                  : null}
              </HStack>
            ) : (
              <HStack pb={20}>
                {socials.map((social, index) => {
                  const IconComponent = iconMapping[social.name];
                  return (
                    <IconButton
                      key={index}
                      size={"lg"}
                      colorScheme={"gray"}
                      variant={"outline"}
                      borderRadius={"full"}
                      icon={
                        typeof IconComponent === "object" ? (
                          IconComponent
                        ) : (
                          <IconComponent
                            theme={social.theme}
                            size={social.iconSize}
                          />
                        )
                      }
                      as={"a"}
                      href={social.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  );
                })}
              </HStack>
            )}
            <VStack
              pb={mode === "partnership" ? 0 : { lg: 24, base: 6 }}
              alignItems={"flex-start"}
            >
              <HStack textTransform={"uppercase"} fontSize={"sm"}>
                <Image
                  borderRadius={"full"}
                  h={8}
                  w={8}
                  mr={1}
                  src={authorImage}
                />
                <Link
                  href={authorLink}
                  isExternal
                  _hover={{ color: "green.300", textDecoration: "underline" }}
                >
                  <Text>{author}</Text>
                </Link>
                <Text>—</Text>
                <Text color={"gray.400"} fontWeight={"semibold"}>
                  {date}
                </Text>
              </HStack>
              {mode === "creatorSpotlight" || mode === "caseStudy" ? (
                <Text
                  textAlign={"left"}
                  as={"i"}
                  sx={{ textWrap: "balance" }}
                  color={"gray.400"}
                >
                  {contextCopy}
                </Text>
              ) : (
                <></>
              )}
            </VStack>
          </VStack>
        </HStack>
      </VStack>
    </Flex>
  );
};

export default BlogHeading;
