import { Flex, Button, VStack, Heading, Text } from "@chakra-ui/react";

const BlogCta = ({ styleType, headline, subline, buttonLink, buttonText }) => {
  if (styleType === "spotlight") {
    return (
      <Flex w={"100%"} mx={"auto"} justifyContent={"center"} bg={"rivr.red"}>
        <VStack my={20} maxW={"460px"} textAlign={"center"}>
          <Heading
            as={"h3"}
            pb={3}
            fontSize={"3xl"}
            fontWeight={"semibold"}
            sx={{ textWrap: "balance" }}
          >
            {headline}
          </Heading>
          <Text pb={6}>{subline}</Text>
          <Button
            as={"a"}
            href={buttonLink}
            variant={"solid"}
            bg={"rivr.black"}
            color={"gray.50"}
            size={"lg"}
            aria-label={buttonText}
            _hover={{ bg: "rivr.300" }}
          >
            {buttonText}
          </Button>
        </VStack>
      </Flex>
    );
  }

  if (styleType === "business") {
    return (
      <Flex w={"100%"} mx={"auto"} justifyContent={"center"} bg={"rivr.red"}>
        <VStack my={20} maxW={"460px"} textAlign={"center"}>
          <Heading
            as={"h3"}
            pb={3}
            fontSize={"3xl"}
            fontWeight={"semibold"}
            sx={{ textWrap: "balance" }}
          >
            {headline}
          </Heading>
          <Text pb={6}>{subline}</Text>
          <Button
            as={"a"}
            href={buttonLink}
            variant={"solid"}
            bg={"rivr.black"}
            color={"gray.50"}
            size={"lg"}
            aria-label={buttonText}
            _hover={{ bg: "rivr.300" }}
          >
            {buttonText}
          </Button>
        </VStack>
      </Flex>
    );
  }
};

export default BlogCta;
