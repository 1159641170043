import { useState, useRef } from "react";
import { Box, Image } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import HoverCursor from "./HoverCursor";
import ResultsView from "../../assets/results-view-main.png";

const ImageWithHoverCursor = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [hovering, setHovering] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [tooltipSubText, setTooltipSubText] = useState("");

  const boxRef = useRef(null);

  const handleMouseMove = (e: React.MouseEvent) => {
    const box = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - box.left;
    const y = e.clientY - box.top;

    setCursorPosition({ x, y });

    const centerPlayer = {
      left: box.width * 0.2,
      right: box.width * 0.8,
      top: -1,
      bottom: box.height * 0.65,
    };

    const bottomTimeline = {
      left: box.width * 0.2,
      right: box.width * 0.8,
      top: box.height * 0.65,
      bottom: box.height,
    };

    const leftPanel = {
      left: -1,
      right: box.width * 0.2,
      top: -1,
      bottom: box.height,
    };

    const rightPanel = {
      left: box.width * 0.8,
      right: box.width,
      top: -1,
      bottom: box.height,
    };

    if (
      x > centerPlayer.left &&
      x < centerPlayer.right &&
      y > centerPlayer.top &&
      y < centerPlayer.bottom
    ) {
      setTooltipText("Media Player");
      setTooltipSubText("Quickly navigate your videos");
    } else if (
      x > bottomTimeline.left &&
      x < bottomTimeline.right &&
      y > bottomTimeline.top &&
      y < bottomTimeline.bottom
    ) {
      setTooltipText("The Timeline");
      setTooltipSubText("Effortlessly discover great Moments");
    } else if (
      x > leftPanel.left &&
      x < leftPanel.right &&
      y > leftPanel.top &&
      y < leftPanel.bottom
    ) {
      setTooltipText("Your Moments");
      setTooltipSubText("Explore recommendations, or create your own");
    } else if (
      x > rightPanel.left &&
      x < rightPanel.right &&
      y > rightPanel.top &&
      y < rightPanel.bottom
    ) {
      setTooltipText("Chat & Speech Search");
      setTooltipSubText("Search full chat and speech transcripts");
    } else {
      setTooltipText("Rivr");
      setTooltipSubText("");
    }
  };

  return (
    <Box
      ref={boxRef}
      position={"relative"}
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      w={"fit-content"}
      mx={"auto"}
      borderWidth={1}
      cursor={"none"}
      transition={"all 0.2s"}
      borderColor={"rivr.red"}
      shadow={"0 15px 50px -12px rgba(228, 58, 25, 0.25);"}
      borderRadius={"xl"}
      maxW={"1400px"}
    >
      <Image
        src={ResultsView}
        alt={"Creator Results View"}
        borderRadius={"xl"}
        overflow={"hidden"}
      />
      <AnimatePresence>
        {hovering && (
          <Box
            position={"absolute"}
            left={`${cursorPosition.x}px`}
            top={`${cursorPosition.y}px`}
            pointerEvents={"none"}
            transform={"translate(-50%, -50%)"}
          >
            <HoverCursor text={tooltipText} subtext={tooltipSubText} />
          </Box>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default ImageWithHoverCursor;
