import SpotlightDeejayKnight from "../pages/Resources/Blog/CreatorSpotlights/SpotlightDeejayKnight";
import NewsLowcoAnnounce from "../pages/Resources/Blog/News/NewsLowcoAnnounce";
import NewsAidanAnnounce from "../pages/Resources/Blog/News/NewsAidanAnnounce";

const blogRoutes = [
  {
    path: "/blog/creator-spotlight-deejayknight",
    element: <SpotlightDeejayKnight />,
  },
  {
    path: "/blog/rivr-news-lowco-head-of-product",
    element: <NewsLowcoAnnounce />,
  },
  {
    path: "/blog/rivr-news-aidan-director-of-sales",
    element: <NewsAidanAnnounce />,
  },
];

export default blogRoutes;
