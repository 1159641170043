import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Image,
  useDisclosure,
  ModalCloseButton,
} from "@chakra-ui/react";

const ImageModal = ({ src, alt, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Image
        src={src}
        alt={alt}
        onClick={onOpen}
        cursor={"pointer"}
        {...props}
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={"xl"}
        isCentered
        allowPinchZoom={true}
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ md: "90%", sm: "96%" }}
          h={"fit-content"}
          w={"fit-content"}
        >
          <ModalCloseButton bg={"gray.900"} _hover={{ bg: "gray.800" }} />
          <ModalBody p={0} display={"flex"} justifyContent={"center"}>
            <Image src={src} alt={alt} maxW={"100%"} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImageModal;
